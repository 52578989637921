import axios, { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { JsonApiDocument, Model, Store } from 'json-api-models';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { InfluencerListListModel } from 'api-models';
import { SecondaryButton } from 'components/Button';
import Checkbox from 'components/Checkbox';
import DiscoveryDropdown from 'components/Discovery/Components/DiscoveryDropdown';
import InfluencerListItem from 'components/Discovery/Components/InfluencerListItem';
import Icon from 'components/Icon';
import BreadCrumbs from 'components/InfluencerManagement/BreadCrumbs';
import BlastDirectMessage from 'components/Lists/Components/BlastDirectMessage';
import { atLeastOneHasValue, SORT_ORDER, SORT_TABLE_FIELDS, sortListHandler } from 'components/Lists/helpers';
import LoadingSpinner from 'components/LoadingSpinner';
import MetricBar from 'components/MetricBar';
import DeleteModal from 'components/Modals/DeleteModal';
import Pill from 'components/Pill';
import { SHARE_LIST_FOLDER } from 'constants/hateoas-keys';
import { getOneInfluencerExtraData } from 'contexts/Discovery/helpers';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import usePermissions from 'hooks/usePermissions';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { pathInfluencerManagement, pathInfluencerManagementFolder } from 'routing/PathLookup';
import DiscoveryService from 'services/DiscoveryApi';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';

import Styled from './ListContent.style';
import { MetricBarData } from './helpers';
import { BlastMessageInfluencerModel, IListContent } from './types';

/**
 * ListContent
 * @param {IListContent} props
 * @returns {JSX.Element}
 */
const ListContent = ({ list, listItems, onRefetch, setListToShare, setSidebarIsOpen }: IListContent): JSX.Element => {
	const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
	const [listItemsToDisplay, setListItemsToDisplay] = useState<Model[]>(listItems);
	const [selectedInfluencersForBlastMessage, setSelectedInfluencersForBlastMessage] = useState<BlastMessageInfluencerModel[]>([]);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDeleteModalOpenMultiple, setIsDeleteModalOpenMultiple] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [selectedListItem, setSelectedListItem] = useState<Model | undefined>(undefined);

	const [influencerList, setInfluencerList] = useState<InfluencerListItemType[] | null>(null);
	const [sortProperty, setSortProperty] = useState('followersCount');
	const [sortOrder, setSortOrder] = useState(SORT_ORDER.DESC);
	const { userCan } = useFeaturePermissions(list.links);

	const client = createClient();
	const navigate = useNavigate();
	const location = useLocation();
	const user = useAppSelector((state) => state.user);

	const { isBrandManager } = usePermissions();

	const handleSelectAllRows = () => {
		if (selectedRows.length === listItems.length) {
			setSelectedRows([]);
			setSelectedInfluencersForBlastMessage([]);
		} else {
			setSelectedRows(listItems.map((influencer: Model) => influencer.collabsId));
			setSelectedInfluencersForBlastMessage(
				listItems.map((influencer) => ({
					id: influencer.collabsId,
					username: influencer.username,
					links: {
						profilePictureUrl: influencer.links.profilePictureUrl || '',
					},
				})),
			);
		}
	};

	const handleSelectRow = (id: string) => {
		const selectedListItem = listItems.find((influencer) => influencer.collabsId === id);

		setSelectedRows((prev) => {
			const isExist = prev.some((prevId) => prevId === id);
			if (isExist) {
				return prev.filter((prevId) => prevId !== id);
			} else {
				return prev.concat(id);
			}
		});

		if (selectedListItem) {
			setSelectedInfluencersForBlastMessage((prev) => {
				const isExist = prev.some((prevInfluencer) => prevInfluencer.id === selectedListItem.collabsId);
				if (isExist) {
					return prev.filter((prevInfluencer) => prevInfluencer.id !== selectedListItem.collabsId);
				} else {
					return prev.concat({
						id: selectedListItem.collabsId,
						username: selectedListItem.username,
						links: {
							profilePictureUrl: selectedListItem.links.profilePictureUrl,
						},
					});
				}
			});
		}
	};

	const handleOnClickDelete = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let promises: Promise<AxiosResponse<JsonApiDocument, any>>[] = [];
		const filteredItems = listItems.filter((influencer) => {
			return selectedRows.includes(influencer.collabsId);
		});

		filteredItems.forEach((item) => {
			if (item.links && item.links.delete) {
				promises = [...promises, client.delete<JsonApiDocument>(item.links.delete)];
			}
		});

		setIsLoading(true);
		Promise.all(promises)
			.then(() => {
				setListItemsToDisplay(
					listItems.filter((item) => {
						return !selectedRows.includes(item.attributes.collabsId);
					}),
				);

				toast.success(selectedRows.length === 1 ? `Deleted one item` : `Deleted ${selectedRows.length} items`);
				onRefetch();
				setSelectedRows([]);
				setIsDeleteModalOpenMultiple(false);
			})
			.catch((e) => {
				if (!axios.isCancel(e)) {
					toast.error('Something went wrong');
					console.error('Unable to delete rows: %O', e);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const deleteOneInfluencer = async () => {
		setIsLoading(true);
		selectedListItem &&
			(await client
				.delete(selectedListItem.links.delete)
				.then(() => {
					toast.success(`Deleted ${selectedListItem.attributes.username} from the list`);
					onRefetch();
					setInfluencerList((prev) => {
						if (prev) {
							return prev.filter((influencer) => influencer.id !== selectedListItem.collabsId);
						} else {
							return null;
						}
					});
					setIsDeleteModalOpen(false);
					setSelectedListItem(undefined);
				})
				.catch((err) => {
					console.error(err);
					toast.error(getSomethingWentWrongMessage());
				})
				.finally(() => {
					setIsLoading(false);
				}));
	};

	useEffect(() => {
		if (listItems.length > 0) {
			const currentIds = listItems.map((listItem) => listItem.attributes.collabsId);
			setInfluencerList((prev) => {
				if (prev !== null) {
					return prev.filter((influencerData) => currentIds.includes(influencerData.id));
				} else {
					return null;
				}
			});
		}
	}, [listItems.length]);

	useEffect(() => {
		const models = new Store();
		if (listItems.length > 0) {
			setIsLoading(true);
			const newInfluencerIds = listItems.map((listItem) => listItem.attributes.collabsId);
			DiscoveryService.getFullInfluencerData(newInfluencerIds, true)
				.then((res) => {
					if (res) {
						models.sync(res);
						const influencers = models.findAll('influencer').map((influencer: Model) => {
							const targetListItem = listItems.find((listItem) => listItem.collabsId === influencer.id);
							return {
								id: influencer.id,
								followersCount: influencer.followersCount,
								network: influencer.network,
								networkId: influencer.networkId,
								networkLinks: influencer.network_links,
								profileImageUrl: influencer.profileImageUrl,
								username: influencer.username,
								engagement: influencer.engagement,
								audienceBrief: influencer.audience,
								links: influencer.links,
								age: null,
								country: null,
								gender: null,
								categories: [],
								listItem: targetListItem,
							};
						});
						// @ts-ignore
						setInfluencerList(influencers as InfluencerListItemType[]);
					} else {
						setInfluencerList([]);
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		}

		setListItemsToDisplay(listItems);

		return () => {
			setSelectedListItem(undefined);
			setIsDeleteModalOpen(false);
			setIsDeleteModalOpenMultiple(false);
			setIsLoading(false);
		};
	}, []);

	const handleSortChange = (e: React.MouseEvent) => {
		const { dataset } = e.target as HTMLTableCellElement;
		const property = dataset.sortProperty;

		if (isNil(property) || !atLeastOneHasValue(influencerList as InfluencerListItemType[], property)) {
			return;
		}

		if (property === sortProperty) {
			setSortOrder(sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC);
		} else {
			setSortProperty(property);
		}
	};

	const totalInfluencerInList = listItemsToDisplay?.length ?? 0;
	return (
		<Styled.Wrapper>
			{isBrandManager() || (list.shared && !list.createdByMe) ? (
				<>
					<Styled.ListName>
						{list.name} <Pill title={`${totalInfluencerInList} influencers`}></Pill>
					</Styled.ListName>

					<Styled.Back to={location.state?.previousUrl ?? pathInfluencerManagement()}>
						<Icon name='arrow-left' size='16' />
						<span>Back</span>
					</Styled.Back>
				</>
			) : (
				<BreadCrumbs
					title={
						<Styled.ListName>
							{`${list.name}`} <Pill title={`${totalInfluencerInList} influencers`}></Pill>
						</Styled.ListName>
					}
					homeText={list.folder?.name ?? 'All files'}
					homeUrl={pathInfluencerManagementFolder(list.folder?.id)}
				/>
			)}
			<Styled.ButtonWrapper>
				<Styled.IconContainer>
					{userCan(SHARE_LIST_FOLDER) && (
						<Styled.SharedContainer data-testid='list-content-share-icon'>
							<Styled.IconWrapper
								onClick={() => {
									setListToShare(list as unknown as InfluencerListListModel), setSidebarIsOpen(true);
								}}
							>
								{list.users.length > 0 && list.attributes.shared ? <Icon name='shared' /> : <Icon name='share' />}
							</Styled.IconWrapper>
							{list.users.length > 1 ? <span>{list.createdByMe ? list.users?.filter((u: Model) => u.id !== user.id).length : list.users.length}</span> : null}
						</Styled.SharedContainer>
					)}
				</Styled.IconContainer>
			</Styled.ButtonWrapper>
			<Styled.MetricContainer>
				<MetricBar items={MetricBarData(list)} />
			</Styled.MetricContainer>
			{isLoading ? (
				<LoadingSpinner size='lg' position='center' />
			) : listItemsToDisplay.length > 0 ? (
				<Styled.ListTableContainer>
					<Styled.ActionIconsWrapper>
						{selectedRows.length > 0 && <DiscoveryDropdown selectedItems={selectedRows} />}
						{!isEmpty(selectedInfluencersForBlastMessage) && <BlastDirectMessage selectedInfluencers={selectedInfluencersForBlastMessage} />}
					</Styled.ActionIconsWrapper>
					<Styled.ListTable>
						<Styled.ListTableHead>
							<tr>
								<Styled.ListTableTh>
									<div style={{ marginLeft: '4px' }}>
										<Checkbox
											onChange={handleSelectAllRows}
											checked={selectedRows.length > 0}
											isIndeterminate={selectedRows.length < listItemsToDisplay.length}
										/>
									</div>
								</Styled.ListTableTh>
								{SORT_TABLE_FIELDS.map(({ property, label }) => (
									<Styled.ListTableTh onClick={handleSortChange} title={`Sort by ${label.toLowerCase()}`} key={property}>
										<Styled.TableHeaderInnerWrapper data-sort-property={property}>
											{label}&nbsp;
											<Styled.SortIconWrapper>
												<div
													data-sort-property={property}
													className={classNames('arrow-up', { isActive: property === sortProperty && sortOrder === SORT_ORDER.ASC })}
												/>
												<div
													data-sort-property={property}
													className={classNames('arrow-down', { isActive: property === sortProperty && sortOrder === SORT_ORDER.DESC })}
												/>
											</Styled.SortIconWrapper>
										</Styled.TableHeaderInnerWrapper>
									</Styled.ListTableTh>
								))}
							</tr>
						</Styled.ListTableHead>
						<Styled.ListTableBody data-testid='list-table-body'>
							{influencerList
								? sortListHandler(influencerList, sortProperty, sortOrder).map((influencer) => {
										const isSelected = selectedRows.some((x) => x === influencer.id);
										const listItem: Model[] = listItems.filter((item: Model) => item.attributes.collabsId === influencer.id);
										return (
											<InfluencerListItem
												listItem={listItem[0]}
												key={influencer.id}
												data={influencer}
												onSelect={() => handleSelectRow(influencer.id)}
												isSelected={isSelected}
												isList
												onDeleteFromList={() => {
													setSelectedListItem(influencer.listItem), setIsDeleteModalOpen(true);
												}}
												onFetchListInflencerExtra={getOneInfluencerExtraData}
												dataTestId='influencerListItem'
											/>
										);
									})
								: null}
						</Styled.ListTableBody>
					</Styled.ListTable>
				</Styled.ListTableContainer>
			) : (
				<Styled.NoResultContainer style={{ textAlign: 'center' }}>
					<h2 className='text-center'>There are no influencers in this list yet!</h2>

					{!isBrandManager() && (
						<>
							<p>Search for influencers in the discovery tool to add influencers in this list.</p>
							<Styled.ButtonContainer>
								<SecondaryButton role='link' onClick={() => navigate('/discovery', { state: { previousUrl: location.pathname } })}>
									Go to Discovery
								</SecondaryButton>
							</Styled.ButtonContainer>
						</>
					)}
				</Styled.NoResultContainer>
			)}
			<DeleteModal
				isModalOpen={isDeleteModalOpenMultiple}
				toggleModal={() => setIsDeleteModalOpenMultiple(!isDeleteModalOpenMultiple)}
				title={`Remove ${selectedRows.length} influencers from this list`}
				isFetching={isLoading}
				action={handleOnClickDelete}
			/>
			<DeleteModal
				isModalOpen={isDeleteModalOpen}
				toggleModal={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
				title={`Remove ${selectedListItem?.attributes.username} from this list`}
				isFetching={isLoading}
				action={deleteOneInfluencer}
			/>
		</Styled.Wrapper>
	);
};

export default ListContent;
