import { useRef, useEffect, useState } from 'react';

import { Icon } from 'components';
import InfluencerAvatar from 'components/InfluencerAvatar';
import { Network } from 'constants/socialMedia';

import Styled from './AutoCompleteList.style';

const MIN_HEIGHT = 132;
const MAX_HEIGHT = 350;
const BOTTOM_MARGIN = 10;

export type AutoCompleteOption = {
	id: string;
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	influencer?: any;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AutoCompleteValues = any[];

export type AutoCompleteListProps = {
	options: AutoCompleteOption[];
	fuzzySearchOptions: AutoCompleteOption[];
	onSelect: (label: string, network: Network) => void;
	cursor: number;
	onClickOutside: () => void;
	inputValue: string | null;
	isFetchingDropdownItems: boolean;
};

const AutoCompleteList = ({ options, fuzzySearchOptions, onSelect, cursor, onClickOutside, inputValue, isFetchingDropdownItems }: AutoCompleteListProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const selectedRef = useRef<HTMLLIElement | null>(null);
	const [rectHeight, setRectHeight] = useState<number>(350);

	useEffect(() => {
		function handleResize() {
			const { innerHeight } = window;
			const currentRect = ref.current?.getBoundingClientRect();
			if (currentRect) {
				const correctHeight = innerHeight - currentRect.top - BOTTOM_MARGIN;
				// If the currentHeight is larger than the calculated height or different from the state height, set to between MIN_HEIGHT and MAX_HEIGHT
				if (currentRect.height > correctHeight || rectHeight !== correctHeight) {
					setRectHeight(Math.max(Math.min(correctHeight, MAX_HEIGHT), MIN_HEIGHT));
				} else {
					setRectHeight(MAX_HEIGHT);
				}
			}
		}

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent): void => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				onClickOutside();
			}
		};

		window.addEventListener('click', handleClickOutside, true);
		return () => {
			window.removeEventListener('click', handleClickOutside, true);
		};
	}, [onClickOutside]);

	const renderOption = (option: AutoCompleteOption, index: number) => {
		const isSelected = index === cursor;

		if (isSelected) {
			setTimeout(() => {
				selectedRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
			}, 0);
		}

		const cleanInputValue = inputValue?.replace(/@/g, '') || '';
		const labelParts = option.label.split(new RegExp(`(${cleanInputValue})`, 'gi'));

		return (
			<Styled.AutoSearchData
				key={option.id}
				onClick={() => onSelect(option.label, option.influencer.network)}
				className={isSelected ? 'selected' : ''}
				ref={isSelected ? selectedRef : undefined}
			>
				<div className='d-flex align-center'>
					<InfluencerAvatar
						userName={option.influencer.username}
						network={option.influencer.network}
						displayNetwork
						profileImageUrl={option.influencer.links?.profilePictureUrl ?? ''}
					/>
					<span>
						@
						{cleanInputValue && labelParts.length > 1 ? (
							labelParts.map((part, i) =>
								part.toLowerCase() === cleanInputValue.toLowerCase() ? (
									<span key={i}>{part}</span>
								) : (
									<span className='bold' key={i}>
										{part}
									</span>
								),
							)
						) : (
							<span>{option.label}</span>
						)}
					</span>
				</div>
				<Styled.Select className={isSelected ? 'display' : ''}>Select and find similar</Styled.Select>
			</Styled.AutoSearchData>
		);
	};

	return (
		<Styled.Wrapper ref={ref} height={rectHeight}>
			{(isFetchingDropdownItems || (inputValue && inputValue?.length > 0)) && options.length === 0 && (
				<Styled.AutoSearchWrap>
					<Styled.Loading>
						<Icon name='ai-icon' size='20' /> <div>Press enter to search</div>
					</Styled.Loading>
				</Styled.AutoSearchWrap>
			)}
			{!isFetchingDropdownItems && options.length === 0 && fuzzySearchOptions.length === 0 && inputValue?.length === 0 && (
				<Styled.AutoSearchWrap>
					<Styled.Loading>
						<Styled.HelpText>
							<div>'Combine results by adding multiple profiles in the search bar.'</div>
							<div>
								'Search for any type of keyword. <strong>Use english</strong>.'
							</div>
							<div>'Paste TikTok or Instagram URLs to avoid typos.'</div>
						</Styled.HelpText>
					</Styled.Loading>
				</Styled.AutoSearchWrap>
			)}
			{options.length > 0 && (
				<Styled.AutoSearchWrap>
					<Styled.Label>Search for</Styled.Label>
					{options.map((option, index) => renderOption(option, index))}
				</Styled.AutoSearchWrap>
			)}
			{fuzzySearchOptions.length > 0 && (
				<Styled.AutoSearchWrap>
					<Styled.Label>Did you mean?</Styled.Label>
					{fuzzySearchOptions.map((option, index) => renderOption(option, index + options.length))}
				</Styled.AutoSearchWrap>
			)}
		</Styled.Wrapper>
	);
};

export default AutoCompleteList;
