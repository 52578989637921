import toast from 'services/Toast';

export const DROPDOWN_OPTION = {
	NULL: '',
	OPEN_SUB_FOLDER: 'folder',
	ADD_TO_LIST: 'Add to list',
	ADD_TO_CAMPAIGN: 'Add to campaign',
	ADD_TO_SEGMENT: 'segment',
};

export const handleSuccessToast = (influencers: string[], targetName: string | undefined, itemId: string, selectedOption: string, segmentId?: string) => {
	const number = influencers.length <= 3 ? ['One', 'Two', 'Three'][influencers.length - 1] || `${influencers.length}` : `${influencers.length}`;
	let url = '';

	if (selectedOption === DROPDOWN_OPTION.ADD_TO_LIST) {
		url = `/influencer-management/lists/${itemId}`;
	} else if (selectedOption === DROPDOWN_OPTION.ADD_TO_SEGMENT) {
		url = `/campaigns/${itemId}/segments/${segmentId}`;
	} else {
		url = `/campaigns/${itemId}`;
	}

	toast.successWithLink(`${number} influencer${influencers.length > 1 ? 's' : ''} added to`, `${targetName}`, `${url}`);
};

export const scrollToItem = (id: string) => {
	id &&
		document.getElementById(id)?.scrollIntoView({
			block: 'center',
			behavior: 'smooth',
		});
};
