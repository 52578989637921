import classNames from 'classnames';
import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';

import { TertiaryButton } from 'components/Button';
import Checkbox from 'components/Checkbox';
import InfluencerListItem from 'components/Discovery/Components/InfluencerListItem';
import DiscoveryContext from 'contexts/Discovery';

import Styled from './ResultTable.style';
import { Props, TABLE_FIELDS } from './types';

const ResultTable = ({ list, selectedItems, onSelectAll, onSelectOne, isLoadingNext, setShowSelectedInfluencers, showSelectedInfluencers }: Props) => {
	const isSelected = selectedItems.length > 0;

	const { isLoading, page, referenceProfiles } = useContext(DiscoveryContext);

	const setPage = useContext(DiscoveryContext).setPage;

	const loadMore = () => {
		const pageAsString = parseInt(page || '') + 1;
		setPage(pageAsString.toString());
	};

	return (
		<Styled.TableContainer fullHeight={referenceProfiles.length === 0}>
			<Styled.Table cellSpacing={0}>
				{!isLoading ? (
					<Styled.TableHeader>
						<tr>
							<Styled.CheckboxTh>
								<Checkbox
									testId='select-all-checkbox'
									onChange={() => {
										onSelectAll(), setShowSelectedInfluencers(false);
									}}
									checked={isSelected}
									isIndeterminate={selectedItems.length < list.length}
								/>
							</Styled.CheckboxTh>
							{TABLE_FIELDS.map(({ property, label }) => (
								<Styled.Th key={property}>
									<Styled.TableHeaderInnerWrapper data-sort-property={property} className={classNames({ followers: label === '' })}>
										{label}&nbsp;
									</Styled.TableHeaderInnerWrapper>
								</Styled.Th>
							))}
							<Styled.Th />
						</tr>
					</Styled.TableHeader>
				) : null}
				<Styled.TableBody data-testid='discovery-table-body'>
					{list.map((influencer, index) => {
						const isSelected = selectedItems.some((item) => item === influencer.id);
						const isReferenceProfile = false;
						return isReferenceProfile && !isSelected ? null : (
							<InfluencerListItem
								dataTestId={`influenceritem-${index}`}
								key={`${influencer.id}-${index}`}
								data={influencer}
								onSelect={() => onSelectOne()(influencer.id)}
								isSelected={isSelected}
								isOdd={(index + 1) % 2 === 1}
							/>
						);
					})}
				</Styled.TableBody>
			</Styled.Table>
			{isLoadingNext && list.length > 0 && (
				<Skeleton width='100%' height='70px' borderRadius='10px' style={{ marginTop: '4px' }} containerTestId='load-more-skeleton' />
			)}
			{/* Only show load more if there could be more items */}
			{list.length > 0 && !showSelectedInfluencers && (
				<Styled.LoadMoreWrapper>
					<TertiaryButton onClick={loadMore} isLoading={isLoadingNext} disabled={isLoading || isLoadingNext}>
						Load more
					</TertiaryButton>
				</Styled.LoadMoreWrapper>
			)}
		</Styled.TableContainer>
	);
};

export default ResultTable;
