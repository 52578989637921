// DrawerComponent.js
import { useState, useEffect, useCallback } from 'react';

import InfluencerDetailCardDiscovery from 'components/Discovery/Components/InfluencerDetailCardDiscovery';
import ExpandedTitle from 'components/Discovery/Components/InfluencerListItem/Components/ExpandedTitle/ExpandedTitle';
import WhyDoIGetThisResult from 'components/Discovery/Components/InfluencerListItem/Components/WhyDoIGetThisResult/WhyDoIGetThisResult';
import { SideDrawer } from 'components/SideDrawer';
import { getOneInfluencerExtraData, getReason } from 'contexts/Discovery/helpers';
import { CombinedInfluencerExtraData } from 'contexts/Discovery/types';

const InfluencerDrawer = ({
	influencerId,
	whyDoIGetLink,
	onClose,
	onSelect,
	isSelected,
	profileImageUrl,
}: {
	influencerId: string;
	whyDoIGetLink: string | undefined;
	onClose: () => void;
	onSelect: () => void;
	isSelected: boolean;
	profileImageUrl: string | undefined;
}) => {
	const [influencerData, setInfluencerData] = useState<CombinedInfluencerExtraData | null>(null);
	const [isExpanded, setIsExpanded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [reasons, setReasons] = useState(null);

	const fetchInfluencerData = useCallback(() => {
		getOneInfluencerExtraData(influencerId).then((data) => setInfluencerData(data));
	}, [influencerId]);

	const showReasonHandler = useCallback((url: string) => {
		setLoading(true);
		getReason(url).then((res) => {
			setReasons(res);
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		fetchInfluencerData();
	}, [fetchInfluencerData]);

	if (!influencerData) return null;

	return (
		<SideDrawer
			dataTestId='influencer-drawer'
			sidebarIsOpen={true}
			onClose={onClose}
			title='Influencer stats'
			isExpandable
			isExpanded={isExpanded}
			onDone={() => setIsExpanded(false)}
			setNotExpanded={() => setIsExpanded(false)}
			expandedContent={<WhyDoIGetThisResult isLoading={loading} reasons={reasons} influencerData={influencerData} profileImageUrl={profileImageUrl} />}
			expandedTitle={<ExpandedTitle influencerData={influencerData} onSelect={onSelect} isSelected={isSelected} data={influencerData} />}
			canOnlyCloseModal
		>
			<InfluencerDetailCardDiscovery
				whyDoIGetLink={whyDoIGetLink}
				influencer={influencerData}
				setIsExpanded={(isExpanded) => setIsExpanded(isExpanded)}
				isExpanded={isExpanded}
				showReasonHandler={showReasonHandler}
			/>
		</SideDrawer>
	);
};

export default InfluencerDrawer;
