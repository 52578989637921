import classNames from 'classnames';

import Styled from './Icon.style';
import { SvgIconComponents } from './SvgComponents';
import { IIconProps, IconSize } from './types';

const Icon = (props: IIconProps) => {
	const { size, icon, name, className, testId } = props;
	const formattedClassName = classNames('icon', className);

	const resolveSvgByType = (type: string) => {
		type = type.toLowerCase();
		if (!SvgIconComponents[type]) {
			return null;
		}

		return SvgIconComponents[type];
	};

	const iconName = () => {
		if (name) return name;
		if (icon) return icon;

		return '';
	};

	const SvgIcon = resolveSvgByType(iconName());
	return (
		<Styled.Icon title={props.title} role={props.role} className={formattedClassName} data-testid={testId ?? `icon-${name}`}>
			{SvgIcon ? <SvgIcon width={size} height={size} aria-hidden={true} focusable={false} {...props.svgProps} /> : ''}
		</Styled.Icon>
	);
};

Icon.defaultProps = {
	size: '24' as IconSize,
	role: 'img',
};

export default Icon;
