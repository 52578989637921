import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SecondaryButton } from 'components/Button';
import { HoverButton } from 'components/Button/Button';
import IconButton from 'components/IconButton';
import { useAppSelector, useAppDispatch } from 'views/DataLibrary/hooks';
import { setDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { setModalIsOpen } from 'views/DataLibrary/reducers/ModalSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './SearchDashboard.style';
import SearchDropdown from './SearchDropdown';

const SearchContainer = () => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const navigate = useNavigate();

	const [displaySearch, setDisplaySearch] = useState<boolean>(false);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dashboards: DashboardType[] = useAppSelector((state) => state.dashboards);

	const dispatch = useAppDispatch();

	const selectDashboard = (dashboard: DashboardType) => {
		setDisplaySearch(false);
		dispatch(setDashboard(dashboard));
		navigate(`/data-library/${dashboard.id}`);
	};

	const handleClickOutside = (e: MouseEvent): void => {
		if (inputRef && inputRef.current && !inputRef.current.contains(e.target as Node)) {
			setDisplaySearch(false);
		}
	};
	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (inputRef && inputRef.current && e.key === 'Escape') {
			setDisplaySearch(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keyup', handleEscapeKey, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
			document.removeEventListener('keyup', handleEscapeKey, true);
			setDisplaySearch(false);
		};
	}, []);

	return (
		<Styled.NameContainer ref={inputRef}>
			<Styled.TitleWrapper>
				{dashboard && dashboard.id ? (
					<>
						<HoverButton
							id='background-color-button'
							icon='arrow-down'
							iconRight
							data-testid='dl-dashboard-title'
							onClick={() => setDisplaySearch(!displaySearch)}
							className={classNames({ displaySearch: displaySearch })}
						>
							{dashboard.id ? dashboard.name : 'Dashboards'}
						</HoverButton>
						{dashboard.dashboardUsers && dashboard.dashboardUsers?.length > 0 && (
							<Styled.IconWrapper>
								<IconButton iconName='shared' hoverDisabled testId='icon-shared' />
							</Styled.IconWrapper>
						)}
					</>
				) : dashboards?.length > 0 ? (
					<SecondaryButton onMouseEnter={() => setDisplaySearch(true)}>Select dashboard</SecondaryButton>
				) : (
					<HoverButton icon='plus' onClick={() => dispatch(setModalIsOpen(true))}>
						Create dashboard
					</HoverButton>
				)}
			</Styled.TitleWrapper>
			{displaySearch && <SearchDropdown arrayToFilter={dashboards} onSelect={selectDashboard} />}
		</Styled.NameContainer>
	);
};

export default SearchContainer;
