import { Model } from 'json-api-models';

import Styled from './SearchResult.style';
import SearchResultItem from './SearchResultItem';

type Props = {
	campaignInfluencerOwners: Model[];
	onSelect: (targetInfluencer: Model) => void;
	selectedInfluencers: Model[];
};

/**
 */
const SearchResult = ({ campaignInfluencerOwners, onSelect, selectedInfluencers }: Props) => {
	return (
		<Styled.Wrapper>
			<div className='results'>
				{campaignInfluencerOwners.length > 0
					? campaignInfluencerOwners
							.filter(
								(campaignInfluencerOwner) => !selectedInfluencers?.some((selectedInstagramOwner) => selectedInstagramOwner.id === campaignInfluencerOwner.id),
							)
							.map((campaignInfluencerOwner) => {
								return (
									<SearchResultItem
										key={campaignInfluencerOwner.id}
										influencer={campaignInfluencerOwner.influencer}
										onClick={() => {
											onSelect(campaignInfluencerOwner);
										}}
									/>
								);
							})
					: null}
			</div>
		</Styled.Wrapper>
	);
};

export default SearchResult;
