import { memo } from 'react';

import { SecondaryButton } from 'components/Button';
import { EmptyCard, Profile, ProfileCard, RecentPosts } from 'components/Discovery/Components/ReferenceProfiles/ReferenceProfiles.style';
import InfluencerAvatarWithFollowers from 'components/InfluencerAvatarWithFollowers/InfluencerAvatarWithFollowers';
import { Network } from 'constants/socialMedia';

const MissingProfileItem = memo(({ missingHandle, onClickRemove, network }: { missingHandle: string; onClickRemove: () => void; network: Network }) => (
	<ProfileCard key={missingHandle} data-testid={`profile-${missingHandle}`} isReferenceProfile>
		<Profile>
			<InfluencerAvatarWithFollowers
				hideDropdown
				username={missingHandle}
				network={network}
				networkLink=''
				profileImageUrl=''
				followersCount={0}
				hideEmail
				inDiscovery
			/>
		</Profile>
		<RecentPosts>
			<EmptyCard>
				<p>
					We seem to be missing recent stats for <strong>'{missingHandle}'</strong>.. We’re fetching them for you now, if available.
				</p>
				<SecondaryButton onClick={onClickRemove}>Remove</SecondaryButton>
			</EmptyCard>
		</RecentPosts>
	</ProfileCard>
));

export default MissingProfileItem;
