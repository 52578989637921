import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { ImageDiv } from './Carousel.style';

const SlideImage = ({ backgroundImageUrl, dataTestId }: { backgroundImageUrl: string; dataTestId?: string }) => {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		const image = new Image();
		image.src = backgroundImageUrl;
		image.onload = () => setIsLoaded(true);
	}, [backgroundImageUrl]);

	return (
		<div>
			{!isLoaded && <Skeleton width={250} height={250} />}
			<ImageDiv
				data-testid={dataTestId}
				src={isLoaded ? backgroundImageUrl : ''}
				className={classNames('transition-opacity duration-200', {
					'opacity-100': isLoaded,
				})}
			/>
		</div>
	);
};

export default SlideImage;
