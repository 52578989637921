import classNames from 'classnames';
import { useState, useEffect, useContext, useCallback, useRef } from 'react';

import Checkbox from 'components/Checkbox';
import DiscoveryDropdown from 'components/Discovery/Components/DiscoveryDropdown';
import InfluencerDetailCard from 'components/Discovery/Components/InfluencerDetailCard/';
import InfluencerDrawer from 'components/Discovery/Components/InfluencerDrawer/InfluencerDrawer';
import IconButton from 'components/IconButton';
import InfluencerAvatarWithFollowers from 'components/InfluencerAvatarWithFollowers/InfluencerAvatarWithFollowers';
import Tooltip from 'components/Tooltip';
import { DELETE_INFLUENCER_FROM_LIST } from 'constants/hateoas-keys';
import { Network } from 'constants/socialMedia';
import DiscoveryContext from 'contexts/Discovery';
import { getOneInfluencerExtraData } from 'contexts/Discovery/helpers';
import { InfluencerExtraData, InfluencerListItemType } from 'contexts/Discovery/types';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { formatNumberWithDecimals } from 'shared/helpers/Chart/chart-util';
import colors from 'styles/theme/colors';

import InfluencerListItemComments from './Components/Comments/InfluencerListItemComments';
import InfluencerListItemReactions from './Components/Comments/InfluencerListItemReactions';
import Styled from './InfluencerListItem.style';
import { defaultData, getNameAndRatio } from './helpers';
import { InfluencerListItemProps } from './types';

const InfluencerListItem = ({
	onSelect,
	data,
	isSelected,
	isList = false,
	onFetchListInflencerExtra,
	onDeleteFromList,
	listItem,
	nextPageRef,
	isOdd,
	dataTestId,
}: InfluencerListItemProps) => {
	const [influencerData, setInfluencerData] = useState<InfluencerListItemType>(defaultData);
	const [influencerExtraData, setInfluencerExtraData] = useState<InfluencerExtraData | null>(null);
	const [showDetail, setShowDetail] = useState(false);
	const [isHovered, setIsHovered] = useState(false); // Track hover state
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const WhyDoIGetThisResultLink = data.links?.explain;

	const { userCan } = useFeaturePermissions({ ...(isList ? data.listItem.links : {}), ...data.links });
	const closeTimeout = useRef<NodeJS.Timeout | null>(null);

	const openMessageModal = useContext(DiscoveryContext).openMessageModal;

	const getInfluencerDetail = useCallback(
		(id: string) => {
			getOneInfluencerExtraData(id).then((res) => {
				setInfluencerData(res);
			});
		},
		[getOneInfluencerExtraData],
	);

	const fetchInfluencerExtraDataHandler = useCallback(() => {
		if (!showDetail && onFetchListInflencerExtra) {
			onFetchListInflencerExtra(data.id).then((res) => {
				setInfluencerExtraData(res);
			});
		}
		setShowDetail((prev) => !prev);
	}, [showDetail, onFetchListInflencerExtra, data.id]);

	useEffect(() => {
		if (influencerExtraData) {
			setInfluencerData((prev) => ({ ...prev, ...influencerExtraData }));
		}
	}, [influencerExtraData]);

	useEffect(() => {
		if (data) {
			setInfluencerData((prev) => ({ ...prev, ...data }));
		}
	}, [data]);

	const open = useCallback(() => {
		openMessageModal(influencerData);
	}, [openMessageModal, influencerData]);

	useEffect(() => {
		if (showDetail) {
			const focusElement = document.getElementById(`focus-${data.id}`); // An element elsewhere
			if (focusElement) focusElement.focus();
		}
	}, [showDetail]);

	// Handle mouse enter with delay
	const handleMouseEnter = () => {
		// Clear any existing timeout to close
		if (closeTimeout.current) {
			clearTimeout(closeTimeout.current);
			closeTimeout.current = null;
		}
		setIsHovered(true);
		setIsDropdownOpen(true);
	};

	// Handle mouse leave with delay
	const handleMouseLeave = () => {
		setIsHovered(false);
		closeTimeout.current = setTimeout(() => {
			setIsDropdownOpen(false);
		}, 500);
	};

	// Cleanup timeout on component unmount
	useEffect(() => {
		return () => {
			if (closeTimeout.current) {
				clearTimeout(closeTimeout.current);
			}
		};
	}, []);

	return (
		influencerData && (
			<>
				<Styled.Wrapper
					id={`focus-${data.id}`}
					data-testid={dataTestId}
					ref={nextPageRef}
					className={classNames({ odd: isOdd && !showDetail, isSelected: isSelected })}
					onClick={
						isList
							? fetchInfluencerExtraDataHandler
							: (e) => {
									e.preventDefault();
									getInfluencerDetail(data.id);
									setShowDetail((prev) => !prev);
								}
					}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<Styled.Td>
						<Tooltip content='Select influencer'>
							<Checkbox
								checked={isSelected}
								onChange={(e) => {
									e.stopPropagation();
									onSelect();
								}}
							/>
						</Tooltip>
					</Styled.Td>
					<Styled.Td maxWidth='250px'>
						<InfluencerAvatarWithFollowers
							hoverColor={colors.crystalBlueDark}
							inDiscovery
							hideEmail
							username={influencerData.username}
							network={influencerData.network as Network}
							networkLink={influencerData.networkLinks[influencerData.network] ?? influencerData.networkLink}
							profileImageUrl={influencerData.profileImageUrl ?? ''}
							followersCount={influencerData.followersCount}
						/>
					</Styled.Td>
					<Styled.Td>
						<Styled.Data>
							{influencerData.engagement !== null ? formatNumberWithDecimals(influencerData.engagement, '%') : <div className='gray'>N/A</div>}
						</Styled.Data>
					</Styled.Td>
					<Styled.Td>
						<Styled.Data>
							{influencerData.audienceBrief.age.name ? getNameAndRatio(influencerData.audienceBrief.age) : <div className='gray'>N/A</div>}
						</Styled.Data>{' '}
					</Styled.Td>
					<Styled.Td maxWidth='100px'>
						<Styled.Data className='location'>
							{influencerData.audienceBrief.country.name ? getNameAndRatio(influencerData.audienceBrief.country) : <div className='gray'>N/A</div>}
						</Styled.Data>
					</Styled.Td>
					<Styled.Td>
						<Styled.Data>
							{influencerData.audienceBrief.gender.name ? getNameAndRatio(influencerData.audienceBrief.gender) : <div className='gray'>N/A</div>}
						</Styled.Data>
					</Styled.Td>
					<Styled.Td text-align='right'>
						<Styled.ReactionsContainer>
							<div className={`opacity-${isHovered ? '1' : '0'}`}>
								<DiscoveryDropdown
									helpText='Add to list/campaign'
									selectedItems={[influencerData.id]}
									isOpen={isDropdownOpen}
									hoverColor={colors.crystalBlueDark}
								/>
								<IconButton
									hoverColor={colors.crystalBlueDark}
									onClick={(e) => {
										e.stopPropagation();
										open();
									}}
									className='influencer-list-item'
									iconName='private-message'
									helpText='Private message'
								/>
							</div>
							{isList && listItem && (
								<>
									<InfluencerListItemComments listItem={listItem} />
									<InfluencerListItemReactions listItem={listItem} />
								</>
							)}
							{isList && userCan(DELETE_INFLUENCER_FROM_LIST) && onDeleteFromList && (
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										onDeleteFromList();
									}}
									className='influencer-list-item'
									iconName='trash-bin'
									testId={`list-influencer-delete-${data.id}`}
									helpText='Remove'
								/>
							)}
						</Styled.ReactionsContainer>
					</Styled.Td>
				</Styled.Wrapper>
				{showDetail && isList ? (
					<Styled.ExpendWrapper>
						<Styled.Td colSpan={100}>
							<Styled.ExpendInnrWrapper>
								<InfluencerDetailCard
									whyDoIGetLink={WhyDoIGetThisResultLink}
									influencer={influencerData}
									onFetchInfluencerData={(id: string) => {
										getInfluencerDetail(id);
									}}
									onSelect={() => onSelect()}
									isSelected={isSelected}
								/>
							</Styled.ExpendInnrWrapper>
						</Styled.Td>
					</Styled.ExpendWrapper>
				) : (
					showDetail && (
						<InfluencerDrawer
							influencerId={influencerData.id}
							whyDoIGetLink={WhyDoIGetThisResultLink}
							onClose={() => setShowDetail(false)}
							onSelect={onSelect}
							isSelected={isSelected}
							profileImageUrl={influencerData.profileImageUrl ?? ''}
						/>
					)
				)}
			</>
		)
	);
};

export default InfluencerListItem;
