import styled, { DefaultThemeV2 } from 'styled-components';

import colors from 'styles/theme/colors';

type T = { theme: DefaultThemeV2 };

const Pill = styled.div<{ backgroundColor?: string; color?: string; size?: 'sm' | 'md' | 'lg' }>`
	font-size: 0.75rem;
	text-transform: capitalize;
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: ${(props) => (props.size === 'sm' ? '0.1rem 0.3rem' : props.size === 'md' ? '0.2rem 0.5rem' : '0.4rem 1rem')};
	width: fit-content;
	&.number {
		border-radius: 50%;
	}
	div {
		text-align: center;
		line-height: 1.5;
		white-space: nowrap;
	}
	&.client,
	&.company {
		background-color: ${colors.paleGreenTint};
		color: ${colors.smoke};
		margin-left: 5px;
	}
	&.brand,
	&.agent {
		background-color: ${colors.iceBlue} !important;
		color: ${colors.smoke};
		margin-left: 5px;
	}
	&.publisher {
		background-color: ${colors.ash};
	}
	&.archived {
		background-color: ${colors.butter};
	}
	&.status-count {
		font-weight: 600;
		background-color: ${colors.ash};
	}
	&.icons {
		font-weight: 500;
		background-color: ${colors.paleGreenTint};
		padding: 0.1rem 0.4rem;
	}
	&.discovery-filter,
	&.cm-filter,
	&.filter {
		font-weight: 600;
		background-color: ${colors.ash};
		font-weight: 600;
	}
	&.dl-filter,
	&.private {
		background-color: ${colors.ash};
		font-weight: 600;
	}
	&.total {
		background-color: ${colors.mist};
		font-weight: 600;
	}
	&.cm-filter-selected {
		font-weight: 600;
		background-color: ${colors.mist};
	}
	&.approved,
	&.compensated,
	&.statistics_uploaded,
	&.review_approved {
		background-color: ${colors.mint};
		color: ${colors.forestGreen};
	}
	&.waiting_for_upload,
	&.in_review,
	&.change_requested {
		background-color: ${colors.butter};
		color: ${colors.spice};
	}
	&.declined {
		background-color: ${colors.rose};
		color: ${colors.ruby};
	}
	&.purple {
		background-color: ${colors.powderVioletDark};
	}
	&.purple-light {
		background-color: ${colors.powderViolet};
	}
	&.pink {
		background-color: ${colors.lavenderHazeDark};
	}
	&.pink-light {
		background-color: ${colors.lavenderHaze};
	}
`;

export default {
	Pill,
};
