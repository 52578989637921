import { memo, useContext, useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';

import InfluencerDrawer from 'components/Discovery/Components/InfluencerDrawer/InfluencerDrawer';
import { Heading } from 'components/Heading';
import { Network } from 'constants/socialMedia';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerBasicType } from 'contexts/Discovery/types';

import MissingProfileItem from './Components/MissingProfileItem/MissingProfileItem';
import ReferenceProfileItem from './Components/ReferenceProfileItem/ReferenceProfileItem';
import { Container, Profiles, ReferenceProfilesWrapper, Top3ProfilesWrapper, SkeletonContainer, TopResultsContainer } from './ReferenceProfiles.style';
import { Props } from './types';

const ReferenceProfiles = ({ onClickRemove, onSelectOne, selectedInfluencers, referenceProfiles }: Props) => {
	const { searchText, changeSearchValueHandler, userNamesNotFound, isLoading, topThreeProfiles, userNames, searchResult } = useContext(DiscoveryContext);

	const [selectedprofile, setSelectedProfile] = useState<InfluencerBasicType | undefined>(undefined);
	const referenceWrapperRef = useRef<HTMLDivElement>(null);
	const top3WrapperRef = useRef<HTMLDivElement>(null);

	const handleNames =
		searchText
			?.split('@')
			.slice(1)
			.map((handle) => handle.trim()) || [];

	return (
		<Container>
			<Profiles className='reference-profiles-container' data-testid='reference-profiles'>
				{isLoading ? (
					<SkeletonContainer data-testid='skeleton-loader'>
						{Array(1)
							.fill(null)
							.map((_, index) => (
								<div style={{ marginTop: '24px' }} key={`skeleton-${index}`}>
									<Skeleton height={441} width={530} borderRadius={10} />
								</div>
							))}
					</SkeletonContainer>
				) : (
					<>
						<div>
							{searchResult && searchResult?.length > 0 ? (
								userNames.length === 0 && searchText && searchText.length > 0 ? (
									<Heading as='h4' style={{ fontWeight: '400' }}>
										Top results for <span style={{ fontWeight: '600' }}>'{searchText}'</span>
									</Heading>
								) : (
									searchText &&
									searchText.length > 0 && (
										<Heading as='h4' style={{ fontWeight: '400' }}>
											Showing results for <span style={{ fontWeight: '600' }}>'{searchText}'</span>
										</Heading>
									)
								)
							) : null}
							{referenceProfiles.length > 0 && (
								<ReferenceProfilesWrapper
									data-testid='reference-profiles-wrapper'
									ref={referenceWrapperRef}
									style={{ width: referenceProfiles.length > 2 ? '100%' : 'fit-content' }}
									role='region'
									aria-label='Reference profiles'
								>
									{referenceProfiles?.map(({ influencer, media }) => (
										<ReferenceProfileItem
											isReferenceProfile
											key={influencer.id}
											influencer={influencer}
											media={media}
											onClickRemove={onClickRemove}
											setSelectedProfile={setSelectedProfile}
											isSelected={selectedInfluencers?.some((item) => item === influencer.id)}
											onSelect={() => onSelectOne()(influencer.id)}
										/>
									))}
									{userNamesNotFound.map((handle) => (
										<MissingProfileItem
											key={handle}
											missingHandle={handle}
											onClickRemove={() => {
												const updatedHandles = handleNames.filter((h) => h !== handle);
												changeSearchValueHandler({ text: updatedHandles.length > 0 ? `@${updatedHandles.join(' @')}` : null });
											}}
											network={referenceProfiles[0]?.influencer.attributes.network as Network}
										/>
									))}
								</ReferenceProfilesWrapper>
							)}
						</div>
						<div>
							{topThreeProfiles.length > 0 && (
								<TopResultsContainer>
									{userNames.length === 0 ? (
										<Heading as='h4' style={{ fontWeight: '400' }}>
											Top results for <span style={{ fontWeight: '600' }}>{searchText}</span>
										</Heading>
									) : (
										<Heading as='h4' style={{ fontWeight: '400' }}>
											Top profiles like <span style={{ fontWeight: '600' }}>'{searchText}'</span>
										</Heading>
									)}
									<Top3ProfilesWrapper ref={top3WrapperRef} role='region' aria-label='Similar profiles'>
										{topThreeProfiles.map(({ influencer, media }) => (
											<ReferenceProfileItem
												key={influencer.id}
												influencer={influencer}
												media={media}
												setSelectedProfile={setSelectedProfile}
												isSimilar={referenceProfiles.length > 0}
												isSelected={selectedInfluencers?.some((item) => item === influencer.id)}
												onSelect={() => onSelectOne()(influencer.id)}
											/>
										))}
									</Top3ProfilesWrapper>
								</TopResultsContainer>
							)}
						</div>
					</>
				)}
			</Profiles>
			{selectedprofile && (
				<InfluencerDrawer
					influencerId={selectedprofile.id}
					whyDoIGetLink={undefined} // No need for this in this step
					onClose={() => setSelectedProfile(undefined)}
					onSelect={() => onSelectOne()(selectedprofile.id)}
					isSelected={selectedInfluencers?.some((item) => item === selectedprofile.id)}
					profileImageUrl={selectedprofile.attributes.profileImageUrl ?? ''}
				/>
			)}
		</Container>
	);
};

export default memo(ReferenceProfiles);
