import { EmblaCarouselType } from 'embla-carousel';
import { ComponentPropsWithRef, useCallback, useEffect, useState } from 'react';

import Icon from 'components/Icon';

import { CarouselButton } from './Carousel.style';

type UsePrevNextButtonsType = {
	prevBtnDisabled: boolean;
	nextBtnDisabled: boolean;
	onPrevButtonClick: () => void;
	onNextButtonClick: () => void;
};

export const usePrevNextButtons = (emblaApi: EmblaCarouselType | undefined): UsePrevNextButtonsType => {
	const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
	const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

	const onPrevButtonClick = useCallback(() => {
		if (!emblaApi) return;
		emblaApi.scrollPrev();
	}, [emblaApi]);

	const onNextButtonClick = useCallback(() => {
		if (!emblaApi) return;
		emblaApi.scrollNext();
	}, [emblaApi]);

	const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
		setPrevBtnDisabled(!emblaApi.canScrollPrev());
		setNextBtnDisabled(!emblaApi.canScrollNext());
	}, []);

	useEffect(() => {
		if (!emblaApi) return;

		onSelect(emblaApi);
		emblaApi.on('reInit', onSelect).on('select', onSelect);
	}, [emblaApi, onSelect]);

	return {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick,
	};
};

type PropType = ComponentPropsWithRef<'button'>;

export const PrevButton = (props: PropType) => {
	const { children, ...restProps } = props;

	return (
		<CarouselButton type='button' data-testid='prev' {...restProps}>
			<Icon name='arrow-left' size='8' />
			{children}
		</CarouselButton>
	);
};

export const NextButton = (props: PropType) => {
	const { children, ...restProps } = props;

	return (
		<CarouselButton {...restProps} data-testid='next'>
			<Icon name='arrow-right' size='8' />
			{children}
		</CarouselButton>
	);
};
