import { useEffect, useState } from 'react';

import BrandAffiliations from 'components/Discovery/Components/BrandAffiliations/BrandAffiliations';
import CountrySummaryList from 'components/Discovery/Components/CountrySummaryList';
import GenderData from 'components/Discovery/Components/GenderData';
import InfluencerAudienceGraph from 'components/Discovery/Components/InfluencerAudienceGraph/InfluencerAudienceGraph';
import ProfileCard from 'components/Discovery/Components/ProfileCard';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import Grid from 'styles/grid/grid';
import colors from 'styles/variables/colors';

import Styled from './InfluencerDetailCard.style';

const InfluencerDetailCard = (props: {
	influencer: InfluencerListItemType;
	onSelect: () => void;
	isSelected: boolean;
	whyDoIGetLink?: string;
	onFetchInfluencerData: (id: string) => void;
}) => {
	const [datasets, setDatasets] = useState<{ total: number; data: number[]; labels: string[] }>({
		total: 0,
		labels: ['Women', 'Men'],
		data: [100, 200],
	});
	const [countryData, setCountryData] = useState<{ name: string; followers: number }[]>([]);

	useEffect(() => {
		if (props.influencer.audienceDetail) {
			if (props.influencer.audienceDetail.genders) {
				const removedUnknownData: { [key: string]: { count: number | null; ratio: number | null } } = {};
				Object.entries(props.influencer.audienceDetail.genders)
					.filter(([genderKey]) => genderKey !== 'unknown')
					.forEach(([genderKey, value]) => {
						removedUnknownData[genderKey] = value;
					});
				const data = Object.values(removedUnknownData).map((genderValue: { count: number | null; ratio: number | null }) =>
					genderValue.ratio !== null ? genderValue.ratio : 0,
				);
				const labels = Object.keys(removedUnknownData)
					.filter((genderKey) => genderKey !== 'unknown')
					.map((el) => {
						switch (el) {
							case 'male':
							case 'man':
							case 'men':
								return 'Men';
							case 'female':
							case 'woman':
							case 'women':
								return 'Women';
							default:
								return '';
						}
					});
				setDatasets({
					total: props.influencer.followersCount,
					labels: labels,
					data: data,
				});
			}
			if (props.influencer.audienceDetail.countries) {
				const countries = props.influencer.audienceDetail.countries;
				const data = Object.keys(countries).map((country) => {
					return { name: country.toLocaleUpperCase(), followers: countries[country].count || 0 };
				});
				setCountryData(data);
			}
		}
	}, [props.influencer]);

	return (
		<>
			<Styled.Wrapper>
				<Grid.Container
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Grid.Column md={12}>
						<Styled.FlexDiv marginBottom='0.5rem' className='influencer-info'>
							<Styled.Div className='divide-line'>
								<Styled.InfluencerData className='label'>Influencer gender</Styled.InfluencerData>
								<Styled.InfluencerData className='value'>{props.influencer.gender ? props.influencer.gender : 'unknown'}</Styled.InfluencerData>
							</Styled.Div>
							<Styled.Div className='divide-line'>
								<Styled.InfluencerData className='label'>Influencer age</Styled.InfluencerData>
								<Styled.InfluencerData className='value'>{props.influencer.age ? props.influencer.age : 'unknown'}</Styled.InfluencerData>
							</Styled.Div>
							<Styled.Div>
								<Styled.InfluencerData className='label'>Influencer country</Styled.InfluencerData>
								<Styled.InfluencerData className='value'>{props.influencer.country ? props.influencer.country.toUpperCase() : 'unknown'}</Styled.InfluencerData>
							</Styled.Div>
						</Styled.FlexDiv>
					</Grid.Column>
					<Grid.Column md={3}>
						<Styled.DataContainer minHeight='230px'>
							<Styled.DataBlockTitle>Statistics</Styled.DataBlockTitle>
							<ProfileCard data={props.influencer} />
						</Styled.DataContainer>
					</Grid.Column>

					<Grid.Column md={3}>
						<Styled.DataContainer minHeight='230px'>
							<Styled.DataBlockTitle>Age span of followers</Styled.DataBlockTitle>
							{props.influencer.audienceDetail && <InfluencerAudienceGraph audienceAge={props.influencer.audienceDetail.ages} />}
						</Styled.DataContainer>
					</Grid.Column>

					<Grid.Column md={3}>
						<Styled.DataContainer minHeight='230px'>
							<Styled.DataBlockTitle>Top 3 countries</Styled.DataBlockTitle>
							<CountrySummaryList items={countryData} totalFollowers={props.influencer.followersCount} sign='%' isTopThree />
						</Styled.DataContainer>
					</Grid.Column>

					<Grid.Column md={3}>
						<Styled.DataContainer minHeight='230px'>
							<Styled.DataBlockTitle>Gender of followers</Styled.DataBlockTitle>
							<Styled.GenderDataContainer>
								<GenderData data={datasets} colors={[colors.chartPrimary, colors.chartSecondary, colors.chartTertiary]} />
							</Styled.GenderDataContainer>
						</Styled.DataContainer>
					</Grid.Column>
					<Grid.Column md={3}>
						{props.influencer.brandAffiliations && props.influencer.brandAffiliations.length > 0 ? <BrandAffiliations influencer={props.influencer} /> : null}
					</Grid.Column>
				</Grid.Container>
			</Styled.Wrapper>
		</>
	);
};

export default InfluencerDetailCard;
