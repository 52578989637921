import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';

const ContactWrapper = styled.div`
	text-align: center;
	p {
		font-size: 0.875rem;
		margin: 0;
	}
	a.contact-link {
		color: ${colors.DetailContent.linkColor};
		cursor: pointer;
		border-bottom: 1px solid transparent;
		transition: border-bottom 0.2s ease-in-out 0s;

		&:hover {
			color: ${colors.DetailContent.linkColor};
			border-bottom: 1px solid ${colors.DetailContent.linkColor};
		}
	}
`;

const ContentWrapper = styled.div`
	height: 100%;
	padding: 1rem;
	padding-top: 0;
	width: 100vw;
	position: relative;
	display: flex;
	flex-direction: column;
	transition: width 0.2s ease-in;
	background-color: ${newColors.snow};
	@media screen and (${devices.md}) {
		width: 423px;
		padding: 2.5rem;
		padding-top: 0;
	}
`;

const ButtonsWrapper = styled.div`
	display: flex;
	width: 100%;
	gap: 8px;
	border-top: 1px solid ${newColors.ash};
	padding-top: 24px;
	button {
		flex: 1;
	}
`;

const Content = styled.div`
	height: 100%;
	overflow-x: hidden;
	margin-right: -16px;
	padding-right: 16px;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`;

const Title = styled.h2`
	font-size: 1.5rem;
	color: ${colors.mainBlack};
	margin: 0;
	padding-top: 2rem;
`;

const BottomSection = styled.div`
	display: flex;
	flex-direction: column;
	position: sticky;
	bottom: 0;
`;

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${newColors.smoke};
	opacity: 0.1;
	z-index: 1010;
`;

const Styled = {
	ContactWrapper,
	ContentWrapper,
	Content,
	ButtonsWrapper,
	Title,
	BottomSection,
	TitleWrapper,
	Overlay,
};

export default Styled;
