import { InfluencerListItemType } from 'contexts/Discovery/types';

export const convertDateFormat = (value: string) => {
	const months: { [key: number]: string } = {
		0: 'Jan',
		1: 'Feb',
		2: 'Mar',
		3: 'Apr',
		4: 'May',
		5: 'Jun',
		6: 'Jul',
		7: 'Aug',
		8: 'Sep',
		9: 'Oct',
		10: 'Nov',
		11: 'Dec',
	};
	const targetDate = new Date(value);
	const date = targetDate.getDate();
	const month = months[targetDate.getMonth()];
	const year = targetDate.getFullYear();

	return `${date} ${month}, ${year}`;
};

export type InfluencerDetailCardProps = {
	influencer: InfluencerListItemType;
	whyDoIGetLink?: string;
	setIsExpanded: (isExpanded: boolean) => void;
	isExpanded: boolean;
	showReasonHandler: (link: string) => void;
};
