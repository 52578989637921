import useEmblaCarousel from 'embla-carousel-react';
import { memo } from 'react';

import Pill from 'components/Pill';
import colors from 'styles/theme/colors';

import { ButtonsContainer, Container, InnerContainer, NavigationContainer, Slide, ViewportContainer } from './Carousel.style';
import { NextButton, PrevButton, usePrevNextButtons } from './CarouselButtons';
import { DotButton, useDotButton } from './CarouselDotButton';
import SlideImage from './SlideImage';

type Props = {
	imageUrls: string[];
	isSimilar: boolean;
};

const Carousel = ({ imageUrls, isSimilar }: Props) => {
	const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
	const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);
	const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

	return (
		<Container>
			<ViewportContainer ref={emblaRef}>
				<InnerContainer>
					{imageUrls?.map((imageUrl, index) => (
						<Slide key={index}>
							<SlideImage backgroundImageUrl={imageUrl} dataTestId={`slide-image-${index}`} />
							{isSimilar ? (
								<div style={{ position: 'absolute', bottom: '10px', left: '10px' }}>
									<Pill title='Similar profile' backgroundColor={colors.powderViolet} />
								</div>
							) : null}
						</Slide>
					))}
				</InnerContainer>
			</ViewportContainer>
			<ButtonsContainer>
				<PrevButton
					onClick={(e) => {
						e.stopPropagation();
						onPrevButtonClick();
					}}
					disabled={prevBtnDisabled}
				/>
				<NextButton
					onClick={(e) => {
						e.stopPropagation();
						onNextButtonClick();
					}}
					disabled={nextBtnDisabled}
				/>
				<div className='embla__controls'>
					<NavigationContainer>
						{scrollSnaps?.map((_, index) => (
							<DotButton
								key={index}
								onClick={(e) => {
									e.stopPropagation();
									onDotButtonClick(index);
								}}
								className={'embla__dot'.concat(index === selectedIndex ? ' embla__dot--selected' : '')}
							/>
						))}
					</NavigationContainer>
				</div>
			</ButtonsContainer>
		</Container>
	);
};

export default memo(Carousel);
