import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 21 20',
};

export function SvgGender(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.9505 1.28393L19.5991 4.42666C19.5506 4.86058 19.1658 5.16828 18.7396 5.11394C18.3134 5.05959 18.0073 4.66377 18.0558 4.22985L18.1952 2.98392L17.1094 3.85213C17.7442 4.91325 18.1054 6.13248 18.1054 7.42932C18.1054 11.2758 14.928 14.4395 10.8563 14.82V16.3825H12.2328C12.6889 16.3825 13.0586 16.7296 13.0586 17.1577C13.0586 17.5858 12.6889 17.9329 12.2328 17.9329H10.8563V19.2248C10.8563 19.6529 10.4866 20 10.0305 20C9.57437 20 9.20462 19.6529 9.20462 19.2248V17.9329H7.82826C7.37215 17.9329 7.00241 17.5858 7.00241 17.1577C7.00241 16.7296 7.37215 16.3825 7.82826 16.3825H9.20462V14.82C5.13292 14.4394 1.95557 11.2757 1.95557 7.42932C1.95557 3.32638 5.57084 0.000281576 10.0305 0.000281576C12.4809 0.000281576 14.6764 1.00446 16.1573 2.58992L17.2282 1.73365L16.0044 1.57758C15.5782 1.52323 15.2721 1.12741 15.3206 0.693495C15.3692 0.259576 15.754 -0.048126 16.1802 0.00622234L19.2667 0.399848C19.6929 0.454196 19.999 0.850015 19.9505 1.28393ZM3.68591 7.42932C3.68591 10.6531 6.52648 13.2664 10.0305 13.2664C13.5345 13.2664 16.3751 10.6531 16.3751 7.42932C16.3751 4.20558 13.5345 1.59222 10.0305 1.59222C6.52648 1.59222 3.68591 4.20558 3.68591 7.42932Z'
				fill='#575556'
			/>
		</svg>
	);
}
