import { isAxiosError } from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';

import AutoCompleteList from 'components/Discovery/Components/AutoCompleteList';
import { AutoCompleteOption } from 'components/Discovery/Components/AutoCompleteList/AutoCompleteList';
import Styled from 'components/Discovery/DiscoveryContainer.style';
import { extractUsernameAndNetwork, mapAutoCompleteOptions } from 'components/Discovery/helpers';
import IconButton from 'components/IconButton';
import SearchInput from 'components/SearchInput';
import { Network } from 'constants/socialMedia';
import DiscoveryContext from 'contexts/Discovery';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import DiscoveryAuthService from 'services/Authentication/Discovery-api';
import DiscoveryService from 'services/DiscoveryApi';
import { ICollabsResponse, StatusCode } from 'services/Response.types';

type SearchSectionProps = {
	setShowFilter: (prev: boolean) => void;
	isSearching: boolean;
	handleSelectOption: (label: string, network: Network) => void;
	setIsSearching: (isSearching: boolean) => void;
	showFilter: boolean;
	setDidYouMeanOptions: (options: AutoCompleteOption[]) => void;
	searchFieldRef: React.RefObject<HTMLInputElement>;
	wrapperRef: React.RefObject<HTMLDivElement>;
	searchQuery: string;
};

const SearchSection = ({
	setShowFilter,
	isSearching,
	handleSelectOption,
	setIsSearching,
	showFilter,
	setDidYouMeanOptions,
	searchFieldRef,
	wrapperRef,
	searchQuery,
}: SearchSectionProps) => {
	const { searchText, updateNetworkHandler, setFilter, filter, changeSearchValueHandler } = useContext(DiscoveryContext);

	const [cursor, setCursor] = useState(-1);
	const [inputValue, setInputValue] = useState<string | null>(searchQuery);
	const [autoCompleteOptions, setAutoCompleteOptions] = useState<AutoCompleteOption[]>([]);
	const [fuzzySearchOptions, setFuzzySearchOptions] = useState<AutoCompleteOption[]>([]);
	const [isFetchingDropdownItems, setIsFetchingDropdownItems] = useState(false);
	const [isInputFocused, setIsInputFocused] = useState(false);
	const searchContainerRef = useRef<HTMLDivElement>(null);

	const cancel = useRef<AbortController | null>(null);

	// Extract the current profile search term based on cursor position
	const getCurrentProfileTerm = (): string => {
		const field = searchFieldRef.current;
		const currentVal = inputValue ?? '';

		if (field && field.selectionStart !== null) {
			const caretPos = field.selectionStart;
			const before = currentVal.slice(0, caretPos);
			const profileStart = before.lastIndexOf('@');

			if (profileStart !== -1) {
				const afterProfile = currentVal.slice(profileStart);
				const spaceIndex = afterProfile.indexOf(' ');
				const profileEnd = spaceIndex === -1 ? currentVal.length : profileStart + spaceIndex;

				if (caretPos > profileStart && caretPos <= profileEnd) {
					return currentVal.slice(profileStart, profileEnd);
				}
			}
		}

		// If not inside a profile, fallback to the last part
		const safeValue = currentVal.trim().split(/\s+/);
		const lastPart = safeValue[safeValue.length - 1];
		return lastPart.startsWith('@') ? lastPart : '';
	};

	const profileTerm = getCurrentProfileTerm();

	const fetchAutoCompleteData = async () => {
		if (cancel.current) {
			cancel.current.abort(); // Avbryt pågående fetch om en ny börjar
		}
		cancel.current = new AbortController();

		if (!inputValue || inputValue.length < 2 || !isSearching) {
			setAutoCompleteOptions([]);
			setFuzzySearchOptions([]);
			return;
		}

		setIsFetchingDropdownItems(true);

		try {
			const [autoCompleteRes, fuzzySearchRes] = await Promise.all([
				DiscoveryService.autoCompleteSearch(profileTerm, cancel.current.signal),
				DiscoveryService.fuzzySearch(profileTerm, cancel.current.signal),
			]);

			const filterByNetwork = (options: AutoCompleteOption[]) => {
				return options.filter((option) => option.influencer.network === filter.networks);
			};

			const handleResponse = (res: ICollabsResponse, setOptions: (options: AutoCompleteOption[]) => void) => {
				const newOptions = mapAutoCompleteOptions(res.data);
				const filteredOptions = filterByNetwork(newOptions);
				setOptions(filteredOptions);
				setDidYouMeanOptions(newOptions);
			};

			handleResponse(autoCompleteRes, setAutoCompleteOptions);
			handleResponse(fuzzySearchRes, setFuzzySearchOptions);

			cancel.current = null;
		} catch (err: unknown) {
			if (isAxiosError(err) && err.response) {
				if (err.response.status === StatusCode.UNAUTHORIZED) {
					DiscoveryAuthService.requestDiscoveryToken();
				}
				const error = err.response.data.errors?.[0];
				toast.error(error ? error.title : getSomethingWentWrongMessage());
			} else {
				console.error(err);
			}
		} finally {
			setIsFetchingDropdownItems(false);
		}
	};

	useEffect(() => {
		setCursor(-1);
		if (inputValue === '') {
			setAutoCompleteOptions([]);
		}

		if (cancel.current !== null) {
			cancel.current.abort();
			cancel.current = null;
		}

		if (inputValue && inputValue.length >= 2) {
			const timerId = setTimeout(fetchAutoCompleteData, 300);
			return () => clearTimeout(timerId);
		} else {
			setAutoCompleteOptions([]);
			setFuzzySearchOptions([]);
		}
	}, [inputValue, profileTerm]);

	useEffect(() => {
		setInputValue(searchText ?? null);
	}, [searchText]);

	useEffect(() => {
		if (searchQuery) setInputValue(searchQuery);
	}, [searchQuery]);

	const changeHandler = (searchWord: string) => {
		if (inputValue === searchWord) return;

		const { network, username } = extractUsernameAndNetwork(searchWord);
		if (username && network) {
			setInputValue(`@${username}`);
			updateNetworkHandler(network);

			setFilter({ ...filter, networks: network });
			changeSearchValueHandler({ text: `@${username}` });
		} else {
			setInputValue(searchWord);
		}
		setIsSearching(true);
	};

	const keyboardNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const combinedOptions = [...autoCompleteOptions, ...fuzzySearchOptions];

		switch (e.key) {
			case 'ArrowDown':
				e.preventDefault();
				if (combinedOptions.length > 0) {
					setCursor((prev) => (prev < combinedOptions.length - 1 ? prev + 1 : prev));
				}
				break;

			case 'ArrowUp':
				e.preventDefault();
				if (combinedOptions.length > 0) {
					setCursor((prev) => (prev > 0 ? prev - 1 : 0));
				}
				break;

			case 'Escape':
				setCursor(-1);
				setAutoCompleteOptions([]);
				setFuzzySearchOptions([]);
				setIsInputFocused(false);
				break;

			case 'Enter': {
				const field = searchFieldRef.current;
				if (!field) return;
				const fieldValue = field.value;

				if (cursor === -1 && inputValue) {
					setIsInputFocused(false);
					changeSearchValueHandler({ text: fieldValue });
					setInputValue(fieldValue);
				} else if (cursor >= 0 && cursor < combinedOptions.length) {
					const selectedOption = combinedOptions[cursor];
					if (selectedOption) {
						const profile = `@${selectedOption.label}`;
						const start = field.selectionStart ?? fieldValue.length;
						const end = field.selectionEnd ?? start;
						const before = fieldValue.slice(0, start);
						const after = fieldValue.slice(end);

						const profileStart = before.lastIndexOf('@');
						let newValue: string;

						if (profileStart === -1) {
							const spaceBefore = before.endsWith(' ') || before === '' ? '' : ' ';
							const spaceAfter = after.startsWith(' ') || after === '' ? '' : ' ';
							newValue = (before + spaceBefore + profile + spaceAfter + after).replace(/\s+/g, ' ').trim();
						} else {
							const afterProfile = fieldValue.slice(profileStart);
							const spaceIndex = afterProfile.indexOf(' ');
							const profileEnd = spaceIndex === -1 ? fieldValue.length : profileStart + spaceIndex;
							const isInsideProfile = start > profileStart && start <= profileEnd;

							if (isInsideProfile) {
								const updatedValue = fieldValue.slice(0, profileStart) + profile + fieldValue.slice(profileEnd);
								newValue = updatedValue.replace(/\s+/g, ' ').trim();
							} else {
								const spaceBefore = before.endsWith(' ') || before === '' ? '' : ' ';
								const spaceAfter = after.startsWith(' ') || after === '' ? '' : ' ';
								newValue = (before + spaceBefore + profile + spaceAfter + after).replace(/\s+/g, ' ').trim();
							}
						}

						changeSearchValueHandler({ text: newValue });
						field.value = newValue;
						const lengthDiff = newValue.length - fieldValue.length;
						let newCaretPos = start;

						if (profileStart !== -1 && profileStart < start) {
							newCaretPos = start + lengthDiff;
						}

						newCaretPos = Math.min(newCaretPos, newValue.length);

						field.setSelectionRange(newCaretPos, newCaretPos);
						field.focus();
					}
				}
				setIsSearching(false);
				break;
			}
		}
	};

	const clearSearch = () => {
		changeSearchValueHandler({ text: '' });
		setInputValue('');
		setIsSearching(false);
		setAutoCompleteOptions([]);
		setFuzzySearchOptions([]);
	};

	// Update the handleInputFocus function
	const handleInputFocus = () => {
		setIsInputFocused(true);
		setIsSearching(true);
		if (inputValue && inputValue.length >= 1) {
			fetchAutoCompleteData();
		}
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node) && isInputFocused) {
				setIsInputFocused(false);
				setIsSearching(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside, true);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, true);
		};
	}, [isInputFocused]);

	return (
		<Styled.SearchInputSection ref={searchContainerRef}>
			<Styled.SearchInputWrapper ref={wrapperRef}>
				<SearchInput
					ref={searchFieldRef}
					value={inputValue || ''}
					onChange={(e) => {
						changeHandler(e.target.value);
						if (e.target.value.length === 0) clearSearch();
					}}
					onKeyDown={keyboardNavigation}
					onFocus={handleInputFocus}
					onReset={() => {
						changeHandler('');
						clearSearch();
					}}
					placeholder='Find profiles by @username or keywords'
				/>
				<Styled.IconContainer>
					<IconButton iconName='filters' onClick={() => setShowFilter(!showFilter)} />
				</Styled.IconContainer>
			</Styled.SearchInputWrapper>
			{isInputFocused && (
				<AutoCompleteList
					inputValue={inputValue}
					cursor={cursor}
					options={autoCompleteOptions}
					isFetchingDropdownItems={isFetchingDropdownItems}
					fuzzySearchOptions={fuzzySearchOptions}
					onSelect={(label: string, network: Network) => {
						handleSelectOption(label, network);
						const newValue = `@${label}`;
						setInputValue(newValue);
						changeSearchValueHandler({ text: newValue });
						setIsSearching(false);
						setIsInputFocused(false);
					}}
					onClickOutside={() => {}}
				/>
			)}
		</Styled.SearchInputSection>
	);
};

export default SearchSection;
