import { Store } from 'json-api-models';
import { createContext, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Network } from 'constants/socialMedia';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import DiscoveryAuthService from 'services/Authentication/Discovery-api';
import DiscoveryService from 'services/DiscoveryApi';
import { StatusCode } from 'services/Response.types';
import toast from 'services/Toast';

import { buildUrlQuery, defaultFilter, extractFilterFromParams, extractQueryFromParams, formatFilter, getGender, mapResult } from './helpers';
import { DiscoveryContextType, DiscoveryContextProps, FilterType, InfluencerListItemType, ReferenceProfile } from './types';

const DiscoveryContext = createContext<DiscoveryContextType>({
	userNames: [],
	userNamesNotFound: [],
	searchWords: [],
	resultsBasedOn: '',
	isTimeout: false,
	networkError: false,
	isLoading: false,
	isLoadingNext: false,
	searchHandler: () => ({ influencers: [] }),
	searchResult: null,
	nextSearchResult: null,

	changeSearchValueHandler: () => {},
	searchText: null,
	setSearchText: () => {}, // remove this
	hasSearched: false,
	setHasSearched: (_hasSearched: boolean) => {},

	filter: defaultFilter,
	setFilter: () => {},
	resetFilter: () => {},

	updateBrandAffiliationsHandler: () => {},
	updateHashtagsHandler: () => {},

	updateCountryHandler: () => {},
	updateGenderHandler: () => {},
	updateAudienceAgeRangeHandler: () => {},
	updateNetworkHandler: (_network: Network) => {},

	page: null,
	setPage: () => {},
	isLastPage: false,
	setIsLastPage: () => {},

	isMessageModalOpen: false,
	messageTargetInfluencer: undefined,
	setMessageTargetInfluencer: (_influencer: InfluencerListItemType | undefined) => {},
	openMessageModal: (_influencer: InfluencerListItemType) => {},
	closeMessageModal: () => {},
	referenceProfiles: [],
	topThreeProfiles: [],
	removeReferenceProfile: () => {},
});

export const DiscoveryContextProvider = ({ children }: DiscoveryContextProps) => {
	const { search: searchUrl, pathname: discoveryUrl } = useLocation();
	const navigate = useNavigate();
	const queryString = new URLSearchParams(searchUrl);
	const [searchText, setSearchText] = useState<string | null>(null);
	const [isSearchTextReset, setIsSearchTextReset] = useState(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingNext, setIsLoadingNext] = useState<boolean>(true);

	const [isTimeout, setIsTimeout] = useState<boolean>(false);
	const [networkError, setNetworkError] = useState<boolean>(false);

	const [filter, setFilter] = useState<FilterType>(defaultFilter);
	const [page, setPage] = useState<string>('0');

	const [searchResult, setSearchResult] = useState<InfluencerListItemType[] | null>(null);
	const [nextSearchResult, setNextSearchResult] = useState<InfluencerListItemType[] | null>(null);
	const [isLastPage, setIsLastPage] = useState<boolean>(false);
	const [hasSearched, setHasSearched] = useState(false); // Track if a search has been done

	const [isMessageModalOpen, setIsMessageModalOpen] = useState<boolean>(false);
	const [messageTargetInfluencer, setMessageTargetInfluencer] = useState<InfluencerListItemType | undefined>(undefined);

	const [referenceProfiles, setReferenceProfiles] = useState<ReferenceProfile[]>([]);
	const [topThreeProfiles, setTopThreeProfiles] = useState<ReferenceProfile[]>([]);

	const [userNames, setUserNames] = useState<string[]>([]);
	const [userNamesNotFound, setUserNamesNotFound] = useState<string[]>([]);
	const [searchWords, setSearchWords] = useState<string[]>([]);
	const [resultsBasedOn, setResultsBasedOn] = useState<string>('');

	useEffect(() => {
		parseUrlHandler();
	}, []);

	useEffect(() => {
		setSearchResult(null);
		urlChangeHandler();
		setPage('0');
		if (!searchText) {
			setReferenceProfiles([]);
			setTopThreeProfiles([]);
		}
	}, [searchText, filter]);

	const defaultNetwork = '?networks=instagram';
	const tiktokNetwork = '?networks=tiktok';

	useEffect(() => {
		if (searchUrl.length > 0) {
			if (searchUrl === defaultNetwork) {
				setHasSearched(false);
				return;
			}
			if (searchUrl === tiktokNetwork) {
				setHasSearched(false);
				return;
			}

			setHasSearched(true);
			searchHandler();
			// Add scroll to top
			window.scrollTo({ top: 0, behavior: 'smooth' });
		} else {
			setHasSearched(false);
			setReferenceProfiles([]);
		}
	}, [searchUrl]);

	// If page is 0 it means it is the first request and searchResult should be set. If page is 1 or higher it means that the searchResult should get updated with the new influencers.
	useEffect(() => {
		if (page === '0') {
			setSearchResult(null);
		} else {
			searchUrl.length > 0 && searchHandler();
		}
	}, [page]);

	useEffect(() => {
		if (nextSearchResult?.length === 0) {
			setIsLastPage(true);
		} else {
			if (searchResult !== null && nextSearchResult !== null) {
				const filteredComingResult = nextSearchResult.filter(
					(comingResult) => !searchResult.some((currentResultItem) => currentResultItem.id === comingResult.id),
				);
				const newSearchResult = [...searchResult, ...filteredComingResult];
				setSearchResult(newSearchResult);
			} else if (nextSearchResult !== null) {
				setSearchResult(nextSearchResult);
			} else {
				setSearchResult(nextSearchResult);
			}
		}
	}, [nextSearchResult]);

	useEffect(() => {
		if (searchUrl === '' && !isSearchTextReset) {
			setSearchText(null);
		}
	}, [isSearchTextReset]);

	useEffect(() => {
		if (searchText !== null) {
			setIsSearchTextReset(false);
		}
	}, [searchText]);

	const extractFilterFromUrl = () => extractFilterFromParams(queryString);

	const extractQueryFromUrl = () => extractQueryFromParams(queryString);

	// Search handlers -----------------------------------
	const parseUrlHandler = () => {
		if (searchUrl) {
			setHasSearched(true);
			const querySearchText = extractQueryFromUrl();

			// Extract other filter parameters
			const qFilter: FilterType = extractFilterFromUrl();

			// Only update searchText if it has changed
			if (searchText !== querySearchText) {
				setSearchText(querySearchText);
			}

			// Only update the filter if it has actually changed
			if (JSON.stringify(filter) !== JSON.stringify(qFilter)) {
				setFilter(qFilter);
			}

			const newPage = queryString.get('page') ? queryString.get('page') : '0';
			if (newPage && newPage !== page) {
				setPage(newPage);
			}
		} else {
			setHasSearched(false);
			setReferenceProfiles([]);
		}
	};

	const urlChangeHandler = () => {
		const newUrl = buildUrlQuery(filter, searchText);
		if (searchUrl !== newUrl && newUrl.length > 1) {
			navigate(`${discoveryUrl}${newUrl}`);
		}
	};

	const changeSearchValueHandler = (param: { text: string | null }) => {
		setSearchText(param.text);
	};

	const cancel = useRef<AbortController | null>(null);

	const searchHandler = () => {
		if (cancel.current) {
			cancel.current.abort();
		}
		cancel.current = new AbortController();
		setIsTimeout(false);
		setNetworkError(false);

		if (page === '0') {
			setIsLoading(true);
		} else {
			setIsLoadingNext(true);
		}

		const filterQueryParam: string | null = searchUrl ? formatFilter(extractFilterFromUrl()) : null;
		// Create timeout promise
		const timeoutPromise = new Promise((_, reject) => {
			setTimeout(() => {
				reject(new Error('Request timeout'));
			}, 30000); // 30 second timeout
		});
		const searchPromise = DiscoveryService.searchInfluencer(
			{
				searchWord: extractQueryFromUrl(),
				filter: filterQueryParam,
			},
			page,
			cancel.current?.signal ?? null,
		);
		setTimeout(() => {
			Promise.race([searchPromise, timeoutPromise])
				.then((res) => {
					if (
						res.data.attributes.notFoundUsernames.length > 0 ||
						res.data.attributes.foundUsernames.length > 0 ||
						res.data.attributes.everythingButUsernames.length > 0
					) {
						setUserNames(res.data.attributes.foundUsernames);
						setSearchWords(res.data.attributes.everythingButUsernames);
						setUserNamesNotFound(res.data.attributes.notFoundUsernames);
						res.data.attributes.notFoundUsernames > 0 &&
							toast.success(`We are fetching ${res.data.attributes.notFoundUsernames.join(', ')} details for you now, if available. `);
						setResultsBasedOn(res.data.attributes.searchString);

						const models = new Store();
						models.sync(res);

						const influencers = models.findAll('influencer');

						// Find influencers that are in foundInfluencers
						const foundInfluencerIds = new Set(res.data.relationships.foundInfluencers.data.map((infl) => infl.id));
						const topThreeIds = new Set(res.data.relationships.topInfluencers.data.map((infl) => infl.id));
						const topInfluencers = influencers
							.filter((item) => topThreeIds.has(item.id))
							.map((influencer) => {
								return {
									influencer,
									media: influencer.whyDoIGetThisResultMedias,
								};
							});

						// If only everythingButUsernames exists, use topInfluencers as referenceProfiles
						if (
							res.data.attributes.everythingButUsernames.length > 0 &&
							res.data.attributes.foundUsernames.length === 0 &&
							res.data.attributes.notFoundUsernames.length === 0
						) {
							setReferenceProfiles(topInfluencers);
							setTopThreeProfiles([]);
						} else {
							const referenceInfluencers = influencers
								.filter((item) => foundInfluencerIds.has(item.id))
								.map((influencer) => {
									return {
										influencer,
										media: influencer.medias,
									};
								});
							setReferenceProfiles(referenceInfluencers);
							setTopThreeProfiles(topInfluencers);
						}
					}
					return res;
				})
				.then((res) => mapResult(res))
				.then(({ influencers }) => {
					setNextSearchResult(influencers);
				})
				.catch((err) => {
					if (err.message === 'Network Error') {
						toast.error('Network error');
						setNetworkError(true);
					}
					if (err.message === 'Request timeout') {
						setIsTimeout(true);
						toast.error('The request timed out. Please try again.');
					} else if (err.name !== 'AbortError' && err.response) {
						// Handle other errors
						if (err.response.status === StatusCode.UNAUTHORIZED) {
							DiscoveryAuthService.requestDiscoveryToken();
						}
						const error = err.response.data.errors[0];
						error?.title && toast.error(error ? error.title : getSomethingWentWrongMessage());
					}
				})
				.finally(() => {
					setIsLoading(false);
					setIsLoadingNext(false);
				});
		}, 500);
	};

	// Filter handlers -----------------------------------

	const updateCommaSeparatedField = (field: keyof FilterType, value: string, action: 'add' | 'remove') => {
		setFilter((prev: FilterType) => {
			const currentValues = prev[field] ? prev[field]!.split(',') : [];
			let updatedValues: string | null;

			if (action === 'add') {
				updatedValues = [...new Set([...currentValues, value])].join(',');
			} else {
				updatedValues = currentValues.filter((item) => item !== value).join(',') || null;
			}

			return { ...prev, [field]: updatedValues };
		});
	};

	const updateHashtagsHandler = (value: string, action: 'add' | 'remove') => {
		updateCommaSeparatedField('hashtags', value, action);
	};

	const updateBrandAffiliationsHandler = (value: string, option: 'include' | 'exclude', action: 'add' | 'remove') => {
		const field = option === 'include' ? 'brandAffiliations' : 'excludedBrandAffiliations';
		updateCommaSeparatedField(field, value, action);
	};

	const updateGenderHandler = (value: string, option: string) => {
		const key = option;
		let newGenders: string | null = null;
		if (filter) {
			if (filter[key]) {
				const currentGenders = filter[key]!.split(',');
				if (currentGenders.some((gender: string) => gender === getGender(value))) {
					newGenders = currentGenders.filter((gender: string) => gender !== getGender(value)).join(',');
				} else {
					newGenders = currentGenders.concat([getGender(value)]).join(',');
				}
			} else {
				newGenders = getGender(value);
			}
		}

		setFilter((prev: FilterType) => {
			return {
				...prev,
				[key]: newGenders,
			};
		});
	};

	const updateCountryHandler = (value: string, option: string) => {
		const key = option;
		let newCountries: string | null = null;

		if (filter) {
			if (filter[key]) {
				const currentCountries = filter[key]!.split(',');

				// Check if the country already exists in the current list
				if (currentCountries.includes(value)) {
					// If the country exists, remove it
					newCountries = currentCountries.filter((country: string) => country !== value).join(',');
				} else {
					// If the country doesn't exist, add it
					newCountries = currentCountries.concat([value]).join(',');
				}
			} else {
				// If there are no current countries, start with the selected value
				newCountries = value;
			}
		}

		setFilter((prev: FilterType) => {
			return {
				...prev,
				[key]: newCountries,
			};
		});
	};

	const updateAudienceAgeRangeHandler = (value: string) => {
		let newAudienceAgeRange: string | null = null;

		// Normalize the value: treat '65+' as '65'
		const normalizedValue = value === '65+' ? '65' : value;

		if (filter) {
			if (filter.audienceAgeRanges) {
				const currentAgeRanges = filter.audienceAgeRanges.split(',');

				// Check if the normalized value (e.g. '65') is already in the current list
				if (currentAgeRanges.includes(normalizedValue)) {
					// If it exists, remove it from the list (deselect)
					newAudienceAgeRange = currentAgeRanges.filter((range) => range !== normalizedValue).join(',');
				} else {
					// If it doesn't exist, add it (select), and ensure uniqueness with Set
					newAudienceAgeRange = [...new Set(currentAgeRanges.concat(normalizedValue))].join(',');
				}
			} else {
				// If there are no current age ranges, start with the normalized value
				newAudienceAgeRange = normalizedValue;
			}
		}

		// Update the filter with the new audience age ranges
		setFilter((prev: FilterType) => {
			return {
				...prev,
				audienceAgeRanges: newAudienceAgeRange,
			};
		});
	};

	const updateNetworkHandler = (value: Network) => {
		// Clear search-related state when changing networks
		setReferenceProfiles([]);
		setSearchText(null);
		setHasSearched(false);

		// Update the filter
		setFilter((prev: FilterType) => ({
			...prev,
			networks: value,
		}));
	};

	const resetFilter = () => {
		setFilter(defaultFilter);
	};

	// Message handlers -----------------------------------
	const openMessageModalHandler = (targetInfluencer: InfluencerListItemType) => {
		setMessageTargetInfluencer(targetInfluencer);
		setIsMessageModalOpen(true);
	};

	const closeMessageModalHandler = () => {
		setMessageTargetInfluencer(undefined);
		setIsMessageModalOpen(false);
	};
	const removeReferenceProfile = (influencerId: string) => {
		setReferenceProfiles((prevProfiles) => prevProfiles.filter((profile) => profile.influencer.id !== influencerId));

		// Update search text
		setSearchText((prevSearchText) => {
			if (!prevSearchText) return prevSearchText;

			// Find the username
			const profileToRemove = referenceProfiles.find((profile) => profile.influencer.id === influencerId);
			if (!profileToRemove) return prevSearchText;

			const usernameToRemove = `@${profileToRemove.influencer.attributes.username}`;
			const updatedSearchText = prevSearchText
				.split(' ')
				.filter((text) => text !== usernameToRemove)
				.join(' ');

			return updatedSearchText.trim();
		});
	};

	const context = {
		userNames: userNames,
		userNamesNotFound: userNamesNotFound,
		searchWords: searchWords,
		resultsBasedOn: resultsBasedOn,
		isLoading: isLoading,
		isLoadingNext: isLoadingNext,
		filter: filter,
		page: page,
		setPage: setPage,
		isLastPage: isLastPage,
		setIsLastPage: setIsLastPage,
		searchHandler: searchHandler,
		searchResult: searchResult,
		nextSearchResult: nextSearchResult,
		changeSearchValueHandler: changeSearchValueHandler,
		searchText: searchText,
		setFilter: setFilter,
		resetFilter: resetFilter,

		updateHashtagsHandler: updateHashtagsHandler,
		updateBrandAffiliationsHandler: updateBrandAffiliationsHandler,

		updateGenderHandler: updateGenderHandler,
		updateAudienceAgeRangeHandler: updateAudienceAgeRangeHandler,
		isMessageModalOpen: isMessageModalOpen,
		messageTargetInfluencer: messageTargetInfluencer,
		setMessageTargetInfluencer: setMessageTargetInfluencer,
		openMessageModal: openMessageModalHandler,
		closeMessageModal: closeMessageModalHandler,
		updateNetworkHandler: updateNetworkHandler,
		hasSearched: hasSearched,
		setHasSearched: setHasSearched,
		updateCountryHandler: updateCountryHandler,
		referenceProfiles: referenceProfiles,
		removeReferenceProfile,
		isTimeout,
		networkError,
		topThreeProfiles,
	};

	const DiscoveryContextProps = {
		value: context,
	};

	return <DiscoveryContext.Provider {...DiscoveryContextProps}>{children}</DiscoveryContext.Provider>;
};

export default DiscoveryContext;
