/* eslint-disable import/extensions */
import { Player } from '@lottiefiles/react-lottie-player';
import { useContext, useEffect, useState } from 'react';

import FetchinTiktok from 'assets/animations/No-result-TT.json';
import FetchinInstagram from 'assets/animations/No-result-ig.json';
import { AutoCompleteOption } from 'components/Discovery/Components/AutoCompleteList/AutoCompleteList';
import ResultTable from 'components/Discovery/Components/ResultTable';
import EmptyState from 'components/EmptyState/V2';
import InfluencerAvatar from 'components/InfluencerAvatar';
import { Network } from 'constants/socialMedia';
import DiscoveryContext from 'contexts/Discovery';
import toast from 'services/Toast';

import Styled from './ResultContainer.style';

type ResultContainerProps = {
	isInfluencerSearch: boolean;
	isLoadingNext: boolean;
	dataToDisplay: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
	selectedInfluencers: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
	onSelectAll: () => void;
	onSelectOne: () => (id: string) => void;
	setShowSelectedInfluencers: (show: boolean) => void;
	clearSearch: () => void;
	fuzzySearchOptions: AutoCompleteOption[];
	handleSelectOption: (text: string, network: Network) => void;
	showSelectedInfluencers: boolean;
};

const ResultContainer = ({
	isLoadingNext,
	dataToDisplay,
	selectedInfluencers,
	onSelectAll,
	onSelectOne,
	setShowSelectedInfluencers,
	showSelectedInfluencers,
	clearSearch,
	fuzzySearchOptions,
	handleSelectOption,
}: ResultContainerProps) => {
	const { isLoading, searchText, filter, searchWords, userNamesNotFound, isTimeout, networkError, hasSearched, searchResult } = useContext(DiscoveryContext);
	const [showEmptyState, setShowEmptyState] = useState(false);
	const [hasShownToast, setHasShownToast] = useState(false);

	useEffect(() => {
		let delayTimeout: NodeJS.Timeout | null = null;

		if (!isLoading && searchResult === null && hasSearched) {
			delayTimeout = setTimeout(() => {
				setShowEmptyState(true);
				if (userNamesNotFound?.length > 0 && !hasShownToast) {
					toast.success(`We are fetching ${userNamesNotFound.join(', ')} details for you now, if available. `);
					setHasShownToast(true);
				}
			}, 400);
		} else {
			setShowEmptyState(false);
			setHasShownToast(false);
		}

		return () => {
			if (delayTimeout) clearTimeout(delayTimeout);
		};
	}, [isLoading, searchResult]);

	useEffect(() => {
		if (isTimeout) {
			setShowEmptyState(true);
		}
	}, [isTimeout]);

	const renderEmptyStateText = () => {
		if (isTimeout) {
			return <p>The request timed out. Please try again.</p>;
		}
		if (userNamesNotFound?.length > 0) {
			return (
				<p>
					Looking for <strong>{userNamesNotFound.join(', ')}</strong> on <Styled.Network>{filter.networks}</Styled.Network>. Please try again in a few minutes.
				</p>
			);
		}
		if (networkError) {
			return <p>Network error</p>;
		}
		if (searchWords) {
			return (
				<p>
					We couldn't find <Styled.Network>{filter.networks}</Styled.Network> match for <strong>'{searchText}'</strong>. try another search
				</p>
			);
		}
		if (!searchText || searchText.trim() === '') {
			return <p>No results, try removing some filters.</p>;
		}
	};

	return (
		<Styled.Wrapper>
			{isLoading || dataToDisplay.length > 0 ? (
				<ResultTable
					setShowSelectedInfluencers={setShowSelectedInfluencers}
					showSelectedInfluencers={showSelectedInfluencers}
					isLoadingNext={isLoadingNext}
					list={dataToDisplay}
					selectedItems={selectedInfluencers}
					onSelectAll={onSelectAll}
					onSelectOne={onSelectOne}
				/>
			) : (
				showEmptyState &&
				dataToDisplay?.length === 0 && (
					<>
						<Styled.EmptyResultImgWrapper>
							<EmptyState
								altText='No result found'
								testId='no-result-found'
								text={
									<>
										{userNamesNotFound?.length > 0 && (
											<Player
												src={filter.networks === Network.INSTAGRAM ? FetchinInstagram : FetchinTiktok}
												autoplay={true}
												loop
												style={{ height: '200px', width: '200px' }}
											/>
										)}
										{renderEmptyStateText()}
										<br />
										{!networkError && (
											<>
												<p>
													<strong>Pro tip!</strong> Copy and paste the profile's {filter.networks} URL to avoid typos.
												</p>
												<Styled.CTAButton onClick={clearSearch}>Try a new search</Styled.CTAButton>
											</>
										)}
									</>
								}
							/>
						</Styled.EmptyResultImgWrapper>
						{(fuzzySearchOptions?.length ?? 0) > 0 && (
							<Styled.FuzzyWrapper>
								<h5>Or did you mean?</h5>
								<Styled.FuzzyContainer>
									{fuzzySearchOptions?.map((result, index) => {
										return (
											<Styled.FyzzyOption
												key={index}
												onClick={() => {
													handleSelectOption(result.label, result.influencer.network);
													window.scrollTo({ top: 0, behavior: 'smooth' });
												}}
											>
												<div>
													<InfluencerAvatar
														size='lg'
														userName={result.influencer.username}
														profileImageUrl={result.influencer.links.profilePictureUrl}
														displayNetwork
														network={result.influencer.network}
													/>
													<strong>{result.label}</strong>
												</div>
												<Styled.OptionButton>View profile</Styled.OptionButton>
											</Styled.FyzzyOption>
										);
									})}
								</Styled.FuzzyContainer>
							</Styled.FuzzyWrapper>
						)}
					</>
				)
			)}
		</Styled.Wrapper>
	);
};

export default ResultContainer;
