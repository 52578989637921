import moment from 'moment';
import { useEffect } from 'react';

import { DiscoveryContainer } from 'components/Discovery';
import ForbiddenPage from 'components/ErrorPages/Forbidden';
import { DiscoveryContextProvider } from 'contexts/Discovery/DiscoveryContext';
import usePermissions from 'hooks/usePermissions';
import { ROLE_PROJECT_MANAGER } from 'hooks/usePermissions/types';
import DiscoveryAuthService from 'services/Authentication/Discovery-api';
import { StoredDiscoveryToken } from 'services/Authentication/Discovery-api/types';

import Styled from './DiscoveryPage.style';

const DiscoveryPage = () => {
	const { isGranted } = usePermissions();

	useEffect(() => {
		const discoveryToken: null | StoredDiscoveryToken = DiscoveryAuthService.getDiscoveryToken();
		if (discoveryToken) {
			const expiresAt = moment(new Date(discoveryToken.expiresAt.toString()));
			const now = moment();
			if (expiresAt < now) {
				DiscoveryAuthService.requestDiscoveryToken();
			}
		} else {
			DiscoveryAuthService.requestDiscoveryToken();
		}
	}, []);

	if (!isGranted(ROLE_PROJECT_MANAGER)) {
		return <ForbiddenPage />;
	}

	return (
		<DiscoveryContextProvider>
			<Styled.Wrapper>
				<DiscoveryContainer />
			</Styled.Wrapper>
		</DiscoveryContextProvider>
	);
};

export default DiscoveryPage;
