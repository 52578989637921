import { JsonApiDocument } from 'json-api-models';
import { useState, useEffect, useCallback } from 'react';

import { ListCampaignsQuery } from 'api-queries';
import IconButton from 'components/IconButton';
import { SideDrawer } from 'components/SideDrawer';
import Tabs from 'components/Tabs/Tabs';
import useCampaignData from 'hooks/Chart/useCampaignData';
import useListData from 'hooks/Chart/useListData';
import { getErrorMessageOnPost, getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import CampaignManager from 'services/ApiManager/Campaign.manager';
import SegmentManager from 'services/ApiManager/Segment.manager';
import { isSuccessfulResponse } from 'services/Response.types';
import toast from 'services/Toast';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import CampaignListMenuItems from './CampaignListMenuItems';
import FolderListMenuItems from './FolderListMenuItems';
import { DROPDOWN_OPTION, handleSuccessToast } from './helpers';

const DiscoveryDropdown = ({
	selectedItems,
	isOpen,
	onClickClose,
	hoverColor,
}: {
	selectedItems: string[];
	isOpen?: boolean;
	onClickClose?: () => void;
	hoverColor?: string;
}) => {
	const [isInstanceOpen, setIsInstanceOpen] = useState(isOpen ?? false);
	const [isLoadingLists, setIsLoadingLists] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [selectedOption, setSelectedOption] = useState(DROPDOWN_OPTION.ADD_TO_LIST);
	const [foldersAndLists, setFoldersAndLists] = useState<{ folders: JsonApiDocument; lists: JsonApiDocument } | null>(null);

	const manager = useInjection<CampaignManager>(CampaignManager);
	const queryBuilder = RequestQueryBuilder.create<ListCampaignsQuery>(['smallCoverPhoto', 'addInfluencers', 'createSegment'])
		.withInclude('segments:hateoas(false)')
		.withFilter('statuses', 'draft,active');

	const segmentManager = useInjection<SegmentManager>(SegmentManager);

	const { repository, result, isLoading } = manager.listCampaigns(queryBuilder, { keepPreviousData: true });

	const { setInfluencersToCampaign } = useCampaignData();

	const { getAllFoldersAndLists, addInfluencersToList } = useListData();

	const saveInfluencersHandler = (influencers: string[]) => {
		setIsSaving(true);

		return async (addInfluencersUrl: string, itemId: string, targetName?: string, segmentId?: string) => {
			if (!addInfluencersUrl || !itemId) {
				toast.error(getSomethingWentWrongMessage());
				setIsSaving(false);
				return;
			}

			try {
				let res;
				switch (selectedOption) {
					case DROPDOWN_OPTION.ADD_TO_CAMPAIGN:
						res = await setInfluencersToCampaign(addInfluencersUrl, influencers);
						if (isSuccessfulResponse(res)) {
							if (segmentId) {
								await segmentManager.addInfluencersTo(segmentId, { influencers });
								handleSuccessToast(influencers, targetName, itemId, 'segment', segmentId);
							} else {
								handleSuccessToast(influencers, targetName, itemId, selectedOption);
							}
						} else {
							toast.error(res !== 0 ? res.toString() : getSomethingWentWrongMessage());
						}
						break;
					case DROPDOWN_OPTION.ADD_TO_LIST:
						res = await addInfluencersToList(itemId, influencers);
						if (isSuccessfulResponse(res)) {
							setIsInstanceOpen(false);
							handleSuccessToast(influencers, targetName, itemId, selectedOption);
						} else {
							toast.error(getSomethingWentWrongMessage());
						}
						break;
					default:
						toast.error(getSomethingWentWrongMessage());
						break;
				}
			} catch (error) {
				toast.error(
					segmentId && selectedOption === DROPDOWN_OPTION.ADD_TO_CAMPAIGN
						? getErrorMessageOnPost('adding influencer to segment')
						: getSomethingWentWrongMessage(),
				);
			} finally {
				setIsSaving(false);
			}
		};
	};

	const getAllInfluencerList = useCallback(() => {
		setIsLoadingLists(true);

		getAllFoldersAndLists()
			.then((res) => {
				if (res) {
					setFoldersAndLists(res as unknown as { folders: JsonApiDocument; lists: JsonApiDocument });
				}
			})
			.catch((err) => {
				console.error(err);
				toast.error(getSomethingWentWrongMessage());
			})
			.finally(() => {
				setIsLoadingLists(false);
			});
	}, [getAllFoldersAndLists]);

	const close = () => {
		setIsInstanceOpen(false);
		onClickClose && onClickClose();
	};

	useEffect(() => {
		if (isInstanceOpen && selectedOption === DROPDOWN_OPTION.ADD_TO_LIST && !foldersAndLists) {
			getAllInfluencerList();
		}
	}, [isInstanceOpen, selectedOption, foldersAndLists, getAllInfluencerList]);

	const renderMenuItems = (closeDrawer?: () => void) => {
		if (selectedItems?.length === 0) {
			return null;
		}

		switch (selectedOption) {
			case DROPDOWN_OPTION.ADD_TO_CAMPAIGN:
				return (
					<CampaignListMenuItems
						repository={repository}
						items={result ?? []}
						isCancellable
						onCancel={() => closeDrawer && closeDrawer()}
						onClick={(url: string, campaignId: string, campaignName: string, segmentId?: string) =>
							saveInfluencersHandler(selectedItems)(url, campaignId, campaignName, segmentId)
						}
						onClose={close}
						selectedItems={selectedItems}
						isSaving={isSaving}
						isLoadingCampaigns={isLoading}
					/>
				);
			case DROPDOWN_OPTION.ADD_TO_LIST:
				return (
					<FolderListMenuItems
						items={foldersAndLists}
						isCancellable
						onCancel={() => closeDrawer && closeDrawer()}
						onClick={(url: string, listId: string, listName: string) => saveInfluencersHandler(selectedItems)(url, listId, listName)}
						onClose={close}
						updateFoldersAndLists={getAllInfluencerList}
						selectedItems={selectedItems}
						isSaving={isSaving}
						isLoadingLists={isLoadingLists}
					/>
				);
		}
	};

	return (
		<>
			<IconButton
				hoverColor={hoverColor}
				iconName='add-new-folder'
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					setIsInstanceOpen(true);
				}}
				testId='toggle-discovery-dropdown-menu'
			/>
			<SideDrawer sidebarIsOpen={isInstanceOpen} onClose={close} dataTestId='discovery-dropdown' title='Add to list'>
				<div style={{ height: '100%', position: 'relative', width: '100%' }}>
					<Tabs
						className='in-drawer-tabs'
						tabs={[
							{ title: 'Add to list', testId: 'add-to-list-button' },
							{ title: 'Add to campaign', testId: 'add-to-campaign-button' },
						]}
						selectedTab={selectedOption}
						setSelectedTab={(tab) => setSelectedOption(tab)}
					/>
					{renderMenuItems(close)}
				</div>
			</SideDrawer>
		</>
	);
};

export default DiscoveryDropdown;
