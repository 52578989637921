import { memo, useMemo } from 'react';

import Checkbox from 'components/Checkbox';
import DiscoveryDropdown from 'components/Discovery/Components/DiscoveryDropdown';
import Carousel from 'components/Discovery/Components/ReferenceProfiles/Components/Carousel';
import {
	ProfileCard,
	Profile,
	RecentPosts,
	HeaderContent,
	StatItem,
	ViewStats,
	CollapsedStatsContainer,
	CollapsedStatItem,
	CollapsedIconContainer,
	CollapsedStatValue,
	CheckboxContainer,
} from 'components/Discovery/Components/ReferenceProfiles/ReferenceProfiles.style';
import { ReferenceProfileItemProps } from 'components/Discovery/Components/ReferenceProfiles/types';
import { Heading } from 'components/Heading';
import Icon from 'components/Icon';
import InfluencerAvatarWithFollowers from 'components/InfluencerAvatarWithFollowers/InfluencerAvatarWithFollowers';
import { Network } from 'constants/socialMedia';
import useWindowDimensions from 'hooks/useWindowDimensions';
import colors from 'styles/theme/colors';
import { breakpoints } from 'styles/variables/media-queries';

const ReferenceProfileItem = memo(
	({ influencer, media, setSelectedProfile, isReferenceProfile, isSimilar, isCollapsed, isSelected, onSelect }: ReferenceProfileItemProps) => {
		ReferenceProfileItem.displayName = 'ReferenceProfileItem';

		const { width } = useWindowDimensions();
		const isMobileView = width <= +breakpoints.lg.split('px')[0];

		// const handleRemoveClick = useCallback(() => onClickRemove && onClickRemove(influencer.id), [onClickRemove, influencer.id]);
		const mediaUrls = useMemo(() => media?.map((m) => m.attributes.url), [media]);
		const StatsItems = [
			{
				icon: 'reach',
				label: 'Engagement rate',
				value: influencer.engagement ? `${(influencer.engagement * 100).toFixed(1)}%` : 'N/A',
			},
			{
				icon: 'activations',
				label: 'Audience age',
				value: influencer.audience?.age.ratio ? `${influencer.audience.age.name} (${(influencer.audience.age.ratio * 100).toFixed(1)}%)` : 'N/A',
			},
			{
				icon: 'location',
				label: 'Audience location',
				value: influencer.audience?.country.ratio
					? `${influencer.audience.country.name?.toUpperCase()} (${(influencer.audience.country.ratio * 100).toFixed(1)}%)`
					: 'N/A',
			},
			{
				icon: 'gender',
				label: 'Audience gender',
				value: influencer.audience?.gender.ratio
					? `${influencer.audience.gender.name?.charAt(0).toUpperCase()}${influencer.audience.gender.name?.slice(1)} (${(influencer.audience.gender.ratio * 100).toFixed(1)}%)`
					: 'N/A',
			},
		];

		return (
			<ProfileCard key={influencer.id} data-testid={`profile-${influencer.id}`} isReferenceProfile={isReferenceProfile}>
				<Profile>
					<CheckboxContainer>
						<Checkbox
							data-testid={`checkbox-${influencer.id}`}
							checked={isSelected}
							onChange={(e) => {
								e.stopPropagation();
								onSelect && onSelect();
							}}
						/>
						<InfluencerAvatarWithFollowers
							scale={isCollapsed ? 'md' : 'lg'}
							username={influencer.attributes.username}
							network={influencer.attributes.network as Network}
							networkLink={influencer.attributes.networkLink ?? ''}
							profileImageUrl={influencer.attributes.profileImageUrl}
							followersCount={influencer.attributes.followersCount}
							hideEmail
							inDiscovery
							hoverColor={isReferenceProfile ? colors.crystalBlue : colors.powderViolet}
						/>
					</CheckboxContainer>
					<DiscoveryDropdown selectedItems={[influencer.id]} isOpen={false} hoverColor={isReferenceProfile ? colors.crystalBlue : colors.powderViolet}>
						{/* {onClickRemove && (
							<RemoveButton
								data-testid='remove-item'
								className='d-flex gap-1 align-center'
								onClick={handleRemoveClick}
								aria-label={`Remove profile for ${influencer.attributes.username}`}
							>
								<Icon name='trash-bin' size='20' /> Remove from search
							</RemoveButton>
						)} */}
					</DiscoveryDropdown>
				</Profile>

				<RecentPosts>
					<div>
						<HeaderContent>
							<Heading as='h5'>Example posts</Heading>
						</HeaderContent>
						<Carousel imageUrls={mediaUrls} isSimilar={!isReferenceProfile && isSimilar === true} />
					</div>
					<div>
						<Heading as='h5'>Overview</Heading>
						{isMobileView ? (
							<CollapsedStatsContainer isSimilar={isSimilar}>
								{StatsItems.map((item) => (
									<CollapsedStatItem key={item.icon}>
										<CollapsedIconContainer isSimilar={isSimilar}>
											<Icon name={item.icon} size='18' style={{ fill: isSimilar ? colors.powderViolet : colors.crystalBlue }} />
										</CollapsedIconContainer>
										<CollapsedStatValue style={{ color: item.value === 'N/A' ? colors.slate : 'inherit' }}>{item.value}</CollapsedStatValue>
									</CollapsedStatItem>
								))}
							</CollapsedStatsContainer>
						) : (
							<div className='stats-container'>
								{StatsItems.map((item) => (
									<StatItem key={item.icon}>
										<div className='icon-container'>
											<Icon name={item.icon} />
										</div>
										<div>
											<div className='stat-label'>{item.label}</div>
											<div className='stat-value' style={{ color: item.value === 'N/A' ? colors.slate : 'inherit' }}>
												{item.value}
											</div>
										</div>
									</StatItem>
								))}
							</div>
						)}
						<ViewStats
							onClick={(e) => {
								e.stopPropagation();
								setSelectedProfile(influencer);
							}}
							data-testid='view-stats-button'
						>
							View all stats
						</ViewStats>
					</div>
				</RecentPosts>
			</ProfileCard>
		);
	},
);

export default ReferenceProfileItem;
