export enum SORT_ORDER {
	ASC,
	DESC,
}

export type AudienceBrief = {
	age: string;
	gender: string;
	country: string;
};

export type Influencer = {
	audienceBrief: AudienceBrief;
	canBeContacted: boolean;
	country: string;
	engagement: number;
	followersCount: number;
	id: string;
	links: { [key: string]: string };
	network: string;
	networkId: string;
	networkLinks: { [key: string]: string };
	profileImageUrl: string;
	username: string;
};

export type Props = {
	list: Array<Influencer>;
	selectedItems: Array<string>;
	isLoadingNext: boolean;
	onSelectAll: () => void;
	onSelectOne: () => (id: string) => void;
	setShowSelectedInfluencers: (show: boolean) => void;
	showSelectedInfluencers: boolean;
};

export const TABLE_FIELDS = [
	{ property: 'followersCount', label: '' },
	{ property: 'engagement', label: 'Engagement' },
	{ property: 'audienceBrief.age.ratio', label: 'Audience age' },
	{ property: 'audienceBrief.country.ratio', label: 'Audience location' },
	{ property: 'audienceBrief.gender.ratio', label: 'Audience gender' },
];
