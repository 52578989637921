import { AxiosError } from 'axios';

import DiscoveryApiClient from 'shared/DiscoveryApiClient';

import { IDiscoveryService, IDiscoveryResponse } from './types';

class DiscoveryService implements IDiscoveryService {
	ENDPOINT_URL: string;

	constructor() {
		this.ENDPOINT_URL = '/api';
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async autoCompleteSearch(param: string, signal: any) {
		const url = `${this.ENDPOINT_URL}/autocomplete?q=${param}`;

		return await DiscoveryApiClient('get', url, undefined, signal).then((response: IDiscoveryResponse) => {
			return response.data;
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async fuzzySearch(param: string, signal: any) {
		const url = `${this.ENDPOINT_URL}/fuzzy?q=${param}`;

		return await DiscoveryApiClient('get', url, undefined, signal).then((response: IDiscoveryResponse) => {
			return response.data;
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async autoCompleteInfluencerSearch(param: string, signal: AbortSignal | null) {
		const url = `${this.ENDPOINT_URL}/search?q=${param.substring(1)}&page=0&usernameAutocomplete=true&networks=instagram,tiktok`;

		return await DiscoveryApiClient('get', url, undefined, signal).then((response: IDiscoveryResponse) => {
			return response.data;
		});
	}

	async searchInfluencer(param: { searchWord: string | null; filter: string | null }, page: string, signal: AbortSignal | null, fuzzy?: boolean) {
		// Build the URL manually to avoid automatic encoding
		let url = `${this.ENDPOINT_URL}/search?page=${page}`;
		url += '&includes=foundInfluencers.medias:maxItems(8),topInfluencers.whyDoIGetThisResultMedias:maxItems(4)';

		if (param.searchWord) {
			url += `&q=${param.searchWord}`;
		}

		if (param.filter) {
			url += `&${param.filter}`;
		}

		if (fuzzy) {
			url += '&username=true';
		}

		return await DiscoveryApiClient('get', url, undefined, signal).then((response: IDiscoveryResponse) => {
			return response.data;
		});
	}

	async getInfluencerExtraData(influencerId: string) {
		const url = `${this.ENDPOINT_URL}/influencer/${influencerId}?includes=extra,related`;

		return await DiscoveryApiClient('get', url)
			.then((response: IDiscoveryResponse) => {
				return response.data;
			})
			.catch((err: AxiosError) => {
				console.error(err);
			});
	}

	async getInstagramPostAndDate(url: string) {
		return await DiscoveryApiClient('get', url)
			.then((response: IDiscoveryResponse) => {
				return response.data;
			})
			.catch((err: AxiosError) => {
				console.error(err);
			});
	}

	async getFullInfluencerData(influencerIds: string[], isPost?: boolean) {
		const targetInfluencerIds = influencerIds.join(',');
		let url = `${this.ENDPOINT_URL}/influencers/full/?includes=extra&ids=${targetInfluencerIds}`;

		if (isPost) {
			url = `${this.ENDPOINT_URL}/influencers/full`;
			return await DiscoveryApiClient('POST', url, { ids: influencerIds })
				.then((response: IDiscoveryResponse) => {
					return response.data;
				})
				.catch((err: AxiosError) => {
					console.error(err);
				});
		}

		return await DiscoveryApiClient('GET', url)
			.then((response: IDiscoveryResponse) => {
				return response.data;
			})
			.catch((err: AxiosError) => {
				console.error(err);
			});
	}

	async lookupInflunecerUsername(username: string, network: string) {
		const selectedNetwork = network ?? 'insagram'; // default to instagram
		const url = `/api/lookup-influencer/${selectedNetwork}/username/${username}?includes=medias:maxItems(8)`;

		return await DiscoveryApiClient('get', url).then((response: IDiscoveryResponse) => {
			return response.data;
		});
	}
}

export default new DiscoveryService();
