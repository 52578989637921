import { useContext } from 'react';

import { Heading } from 'components/Heading';
import DiscoveryContext from 'contexts/Discovery';

import Styled from './SearchExamples.style';
import { Searches } from './helpers';

const SearchExamples = (props: { setShowFilter: (show: boolean) => void }) => {
	const { changeSearchValueHandler, filter, setFilter } = useContext(DiscoveryContext);

	const handleSearch = (text: string) => {
		setFilter({ ...filter, countries: 'SWE', audienceCountries: 'SWE' });
		changeSearchValueHandler({ text: text });
	};

	return (
		<Styled.ExamplesWrapper data-testid='search-examples'>
			<Styled.Text>
				<Heading as='h5'>Find your perfect match</Heading>
				<p>
					Describe the content, search by @username, or{' '}
					<span className='link' onClick={() => props.setShowFilter(true)}>
						start from scratch
					</span>
					. <br />
					You can also explore popular searches below to get started.
				</p>
			</Styled.Text>
			<Styled.CardContainer>
				{Searches.map((search, index) => {
					return (
						<Styled.CardWrapper key={index} onClick={() => handleSearch(search.inputValue)}>
							<Styled.Card img={search.img}></Styled.Card>
							<Styled.SearchText>
								<span>{search.inputValue}</span>
							</Styled.SearchText>
						</Styled.CardWrapper>
					);
				})}
			</Styled.CardContainer>
		</Styled.ExamplesWrapper>
	);
};

export default SearchExamples;
