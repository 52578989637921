import styled, { keyframes } from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';

// Add rotation keyframes
const rotation = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`;

const getSize = (size: string) => {
	switch (size) {
		case 'sm':
			return '24px';
		case 'lg':
			return '56px';
		default:
			return '48px';
	}
};

const getPosition = (position: string) => {
	switch (position) {
		case 'center':
			return 'margin: auto';
		default:
			return '';
	}
};

const getBorderWidth = (size: string) => {
	switch (size) {
		case 'sm':
			return '2.5px';
		case 'lg':
			return '5px';
		default:
			return '5px';
	}
};

const Spinner = styled.div<{ size: 'sm' | 'md' | 'lg'; position: 'default' | 'center'; inverted: boolean }>`
	width: ${(props) => getSize(props.size)};
	height: ${(props) => getSize(props.size)};
	border: ${(props) => getBorderWidth(props.size)} solid ${(props) => (props.inverted ? colors.white : colors.black1)};
	${(props) => getPosition(props.position)};
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: ${rotation} 0.7s linear infinite;
`;

const Title = styled.h2`
	padding-top: 2.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const DrawerTitle = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	div {
		span {
			white-space: nowrap;
		}
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		&.disable {
			cursor: pointer;
			font-size: 0.875rem;
			color: ${newColors.oceanBlue};
			width: fit-content;
			font-weight: 400;
			&:hover {
				font-weight: 600;
			}
		}
	}
	&.sure {
		flex-direction: column;
	}
`;

const AreYouSure = styled.div`
	width: 100%;
	background-color: ${newColors.rose};
	border-radius: ${borderRadius.m};
	font-size: 1rem;
	font-weight: 400;
	flex-direction: column;
	padding: 1rem;
	button {
		margin-top: 24px;
	}
`;

const Styled = {
	Spinner,
	Title,
	DrawerTitle,
	AreYouSure,
};

export default Styled;
