import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { scrollbarX } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import { devices } from 'styles/variables/media-queries';

export const Container = styled.div`
	margin-bottom: 40px;
	margin-top: 40px;
	width: 100%;
`;

export const Profiles = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	gap: 1rem;
`;

export const Top3ProfilesWrapper = styled.div`
	scroll-padding-top: 200px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: row;
	background-color: ${colors.powderVioletDark};
	border-radius: ${borderRadius.m};
	${scrollbarX}
	overflow-y: hidden;
	position: relative;
	top: auto;
	z-index: 3;
	margin-top: 16px;
	height: auto;

	@media screen and (${devices.lg}) {
		max-height: 500px;
	}
`;

export const ReferenceProfilesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	gap: 8px;
	overflow-y: hidden;
	background-color: ${colors.crystalBlueDark};
	border-radius: ${borderRadius.m};
	width: 100vw;
	${scrollbarX};
	z-index: 2;
	margin-top: 40px;

	&:not(.top-results-wrapper) {
		flex-direction: row;
		top: 180px;
	}

	&.top-results-wrapper {
		top: 220px;
		background-color: ${colors.snow};
	}

	@media screen and (${devices.lg}) {
		max-height: 500px;
		margin-top: 0;
	}
`;

export const Profile = styled.div<{ isReferenceProfile?: boolean; isLoading?: boolean }>`
	display: flex;
	border-radius: ${borderRadius.m};
	justify-content: space-between;
	background-color: ${({ isLoading }) => (isLoading ? colors.mist : 'transparent')};
	// Set max smaller max height to prevent overlfow in the y axis
	.dropdown-menu {
		.campaign-list {
			max-height: 250px;
		}

		.folder-list {
			max-height: 200px;
		}
	}

	.discovery-ddl {
		.dropdown-button {
			height: 40px;
			width: 40px;
			padding: 0;

			> div {
				border-radius: 10px !important;
				height: 40px;
				width: 40px;

				&:hover {
					.icon path {
						fill: ${colors.black};
					}
				}
			}

			.icon {
				path {
					transition: fill 0.2s ease-in-out;
					fill: ${colors.slate};
				}
			}
		}
	}

	.dropdown__title {
		max-width: 150px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export const RemoveButton = styled.button`
	display: flex;
	gap: 1rem;
	align-items: center;
	background-color: transparent;
	border: none;
	padding: 0;
	font-weight: ${({ theme }) => theme.fontWeights.medium};
	cursor: pointer;
`;

export const RecentPosts = styled.div<{ isLoading?: boolean }>`
	padding: 1rem;
	margin-top: 8px;
	border-radius: ${borderRadius.m};
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	background-color: ${({ isLoading }) => (isLoading ? colors.mist : 'transparent')};

	h6 {
		margin-bottom: 8px;
	}
	.stats-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-top: 16px;
		width: 200px;
	}
	@media screen and (${devices.lg}) {
		flex-direction: row;
	}
`;

export const EmptyCard = styled.div`
	cursor: pointer;
	border-radius: 10px;
	width: 400px;
	height: 100%;
	border: 1px solid transparent;
	padding: 1rem;
	button {
		margin: auto;
		margin-top: 40px;
	}
`;

export const HeaderContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ViewStats = styled.p`
	color: ${colors.oceanBlue};
	font-size: 14px;
	cursor: pointer;
	display: inline-block;
	text-align: end;
	width: 100%;
	margin-top: 24px;
`;

export const StatItem = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;

	.icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${colors.white};
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}
	.stat-label {
		color: ${({ theme }) => theme.colors.text.secondary};
		font-size: 14px;
		white-space: nowrap;
		margin-top: 6px;
	}

	.stat-value {
		font-size: 16px;
		font-weight: 600;
		line-height: 1.7;
	}
`;

export const ProfileCard = styled.div<{ isReferenceProfile?: boolean; isLoading?: boolean }>`
	padding: 0.5rem;
	border-radius: ${borderRadius.m};
	background-color: ${({ isReferenceProfile, isLoading }) => (isLoading ? colors.mist : isReferenceProfile ? colors.crystalBlueDark : 'transparent')};
	min-height: 90px;
	width: fit-content;
	cursor: default;

	${Profile} {
		top: 0;
		z-index: 1;
		background-color: ${({ isReferenceProfile, isLoading }) =>
			isLoading ? colors.mist : isReferenceProfile ? colors.crystalBlueDark : colors.powderVioletDark};
	}

	${RecentPosts} {
		background-color: ${({ isReferenceProfile, isLoading }) => (isLoading ? colors.mist : isReferenceProfile ? colors.crystalBlue : colors.powderViolet)};
	}

	div {
		&.icon-container {
			background-color: ${({ isReferenceProfile }) => (isReferenceProfile ? colors.crystalBlueDark : colors.powderVioletDark)};
		}
	}

	@media screen and (${devices.lg}) {
		min-width: 440px;
	}
`;

export const SkeletonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
`;

export const TopResultsContainer = styled.div`
	padding-top: 24px;
	background-color: ${colors.snow};
	border-radius: 10px;
	width: 100%;
	z-index: 3;
`;

export const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const CollapsedStatsContainer = styled.div<{ isSimilar?: boolean }>`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	padding: 0 0.5rem;
	background-color: ${({ isSimilar }) => (isSimilar ? colors.powderViolet : colors.crystalBlue)};
	margin-top: 16px;
	border-radius: 10px;
`;

export const CollapsedStatItem = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 8px;
	flex: 1;
	padding: 0.5rem;
`;

export const CollapsedIconContainer = styled.div<{ isSimilar?: boolean }>`
	background-color: ${({ isSimilar }) => (isSimilar ? colors.powderVioletDark : colors.crystalBlueDark)};
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CollapsedStatValue = styled.div`
	font-size: 13px;
	line-height: 1;
	white-space: nowrap;
	font-weight: bold;
`;
