import { isEmpty, uniq } from 'lodash';
import { useEffect, useState, useContext, useRef } from 'react';

import { Heading } from 'components/Heading';
import PageHeader from 'components/PageHeader';
import Tabs from 'components/Tabs/Tabs';
import { Network } from 'constants/socialMedia';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import MainContent from 'styles/layout/main-content';

import { AutoCompleteOption } from './Components/AutoCompleteList/AutoCompleteList';
import FilterSection from './Components/FilterSection/FilterSection';
import MessageModal from './Components/MessageModal';
import ReferenceProfiles from './Components/ReferenceProfiles/ReferenceProfiles';
import ResultContainer from './Components/ResultContainer';
import SearchExamples from './Components/SearchExamples/SearchExamples';
import SearchSection from './Components/SearchSection/SearchSection';
import SelectedInfluencerOptions from './Components/SelectedInfluencerOptions/SelectedInfluencerOptions';
import Styled from './DiscoveryContainer.style';
const DiscoveryContainer = () => {
	const {
		isLoadingNext,
		searchText,
		searchResult,
		setHasSearched,
		filter,
		setFilter,
		isMessageModalOpen,
		hasSearched,
		referenceProfiles,
		removeReferenceProfile,
		changeSearchValueHandler,
		updateNetworkHandler,
		closeMessageModal,
	} = useContext(DiscoveryContext);

	const [showFilter, setShowFilter] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [didYouMeanOptions, setDidYouMeanOptions] = useState<AutoCompleteOption[]>([]);

	const [selectedInfluencers, setSelectedInfluencers] = useState<string[]>([]);
	const [selectedInfluencersResult, setSelectedInfluencersResult] = useState<InfluencerListItemType[]>([]);
	const [showSelectedInfluencer, setShowSelectedInfluencers] = useState<boolean>(false);

	const searchFieldRef = useRef<HTMLInputElement | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	const [searchQuery, setSearchQuery] = useState('');

	useEffect(() => {
		setHasSearched(false);
	}, []);

	const selectAllHandler = () => {
		if (searchResult) {
			if (selectedInfluencers.length === searchResult.length) {
				setSelectedInfluencers([]);
			} else {
				setSelectedInfluencers(searchResult.map((influencer: InfluencerListItemType) => influencer.id));
			}
		}
	};

	const selectOneHandler = () => {
		return (id: string) => {
			setSelectedInfluencers((prev) => {
				const isExist = prev.some((prevId) => prevId === id);
				if (isExist) {
					return prev.filter((prevId) => prevId !== id);
				} else {
					return prev.concat(id);
				}
			});
		};
	};

	const searchFieldFocusHandler = () => {
		if (searchFieldRef.current) {
			searchFieldRef.current.focus();

			if (wrapperRef.current) {
				wrapperRef.current.classList.remove('pulse');
				void wrapperRef.current.offsetWidth;
				wrapperRef.current.classList.add('pulse');

				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}

				timeoutRef.current = setTimeout(() => {
					wrapperRef.current?.classList.remove('pulse');
					timeoutRef.current = null;
				}, 800);
			}

			const currentValue = searchFieldRef.current.value;
			setSearchQuery(currentValue.endsWith(' ') ? currentValue : currentValue + ' ');
		}
	};

	useEffect(() => {
		const searchResultFiltered = uniq(searchResult?.filter((result) => selectedInfluencers?.includes(result.id))) || [];
		const savedSearchResultFiltered = uniq(selectedInfluencersResult?.filter((result) => selectedInfluencers?.includes(result.id))) || [];

		if (searchResultFiltered.length && savedSearchResultFiltered.length) {
			setSelectedInfluencersResult(uniq([...searchResultFiltered, ...savedSearchResultFiltered]));
		} else if (!searchResultFiltered.length) {
			setSelectedInfluencersResult(savedSearchResultFiltered);
		} else {
			setSelectedInfluencersResult(searchResultFiltered);
		}
	}, [selectedInfluencers]);

	useEffect(() => {
		setShowSelectedInfluencers(false);
	}, [searchResult]);

	const clearSearch = () => {
		changeSearchValueHandler({
			text: '',
		});
		setIsSearching(false);
		searchFieldRef.current?.focus();
	};

	const handleSelectOption = (label: string, network: Network) => {
		const mention = `@${label}`;
		const field = searchFieldRef.current;
		if (!field) return;

		const fieldValue = field.value;
		const start = field.selectionStart ?? fieldValue.length;
		const end = field.selectionEnd ?? start;

		const before = fieldValue.slice(0, start);
		const after = fieldValue.slice(end);

		// Find the bounds of the current mention if we're inside one
		let mentionStart = -1;
		let mentionEnd = -1;

		// Look backwards from cursor to find start of current mention
		for (let i = start - 1; i >= 0; i--) {
			if (fieldValue[i] === '@') {
				// Check if this @ is the start of a mention (not preceded by a letter/number)
				if (i === 0 || /\s/.test(fieldValue[i - 1])) {
					mentionStart = i;
					break;
				}
			} else if (mentionStart === -1 && /\s/.test(fieldValue[i])) {
				// Stop looking if we hit a space before finding an @
				break;
			}
		}

		// Look forward from cursor to find end of current mention
		for (let i = start; i < fieldValue.length; i++) {
			if (/[\s@]/.test(fieldValue[i])) {
				mentionEnd = i;
				break;
			}
		}
		if (mentionEnd === -1) mentionEnd = fieldValue.length;

		let newValue: string;
		if (mentionStart !== -1 && start > mentionStart && start <= mentionEnd) {
			// We're inside a mention, replace just this mention
			newValue = fieldValue.slice(0, mentionStart) + mention + fieldValue.slice(mentionEnd);
		} else {
			// We're not in a mention, insert a new one
			newValue = `${before}${mention}${after}`;
		}

		// Normalize spaces
		newValue = newValue.replace(/\s+/g, ' ').trim();

		// Update network first
		setFilter({ ...filter, networks: network });
		updateNetworkHandler(network);

		// Then update search text - this should trigger the URL change
		changeSearchValueHandler({ text: newValue });

		// Update field value and focus
		field.value = newValue;
		const newCaretPos = newValue.indexOf(mention, mentionStart !== -1 ? mentionStart : 0) + mention.length;
		field.setSelectionRange(newCaretPos, newCaretPos);
		// field.focus();
	};

	const renderData = () => {
		return showSelectedInfluencer ? selectedInfluencersResult : searchResult ?? [];
	};

	return (
		<Styled.Wrapper data-testid='discovery-container'>
			<Styled.Header>
				<PageHeader />
				<Styled.SearchArea>
					<Styled.TabsWrapper>
						<Tabs
							className='on-discovery'
							tabs={[
								{ title: Network.INSTAGRAM, icon: 'instagram', testId: 'instagram-tab' },
								{ title: Network.TIKTOK, icon: 'tiktok', testId: 'tiktok-tab' },
							]}
							selectedTab={filter?.networks as Network}
							setSelectedTab={(tab) => updateNetworkHandler(tab as Network)}
						/>
					</Styled.TabsWrapper>
					<SearchSection
						setDidYouMeanOptions={setDidYouMeanOptions}
						setShowFilter={setShowFilter}
						showFilter={showFilter}
						isSearching={isSearching}
						handleSelectOption={handleSelectOption}
						setIsSearching={setIsSearching}
						searchFieldRef={searchFieldRef}
						wrapperRef={wrapperRef}
						searchQuery={searchQuery}
					/>
					<FilterSection showFilter={showFilter} setShowFilter={setShowFilter} />
				</Styled.SearchArea>
			</Styled.Header>
			<MainContent>
				{!showSelectedInfluencer && (
					<ReferenceProfiles
						referenceProfiles={referenceProfiles}
						onClickAddReferenceProfile={searchFieldFocusHandler}
						onClickRemove={removeReferenceProfile}
						onSelectOne={selectOneHandler}
						selectedInfluencers={selectedInfluencers}
					/>
				)}
				<div>
					{showSelectedInfluencer ? (
						<Heading as='h4' style={{ fontWeight: '600' }}>
							Selected profiles
						</Heading>
					) : searchResult && searchResult?.length > 0 && !isEmpty(referenceProfiles) ? (
						<Heading as='h4' style={{ fontWeight: '400' }}>
							Showing all results for <span style={{ fontWeight: '600' }}>'{searchText}'</span>
						</Heading>
					) : null}
				</div>
				<SelectedInfluencerOptions
					selectedInfluencers={selectedInfluencers}
					setSelectedInfluencers={setSelectedInfluencers}
					showSelectedInfluencer={showSelectedInfluencer}
					setShowSelectedInfluencers={setShowSelectedInfluencers}
				/>
				<Styled.ResultContentWrapper>
					{
						//Show SearchExamples only if no search has been done
						!hasSearched && renderData()?.length === 0 ? (
							<SearchExamples setShowFilter={setShowFilter} />
						) : (
							<ResultContainer
								setShowSelectedInfluencers={setShowSelectedInfluencers}
								showSelectedInfluencers={showSelectedInfluencer}
								isLoadingNext={isLoadingNext}
								isInfluencerSearch={searchText && searchText.startsWith('@') ? true : false}
								dataToDisplay={renderData()}
								selectedInfluencers={selectedInfluencers}
								onSelectAll={selectAllHandler}
								onSelectOne={selectOneHandler}
								handleSelectOption={handleSelectOption}
								clearSearch={clearSearch}
								fuzzySearchOptions={didYouMeanOptions}
							/>
						)
					}
				</Styled.ResultContentWrapper>
			</MainContent>
			<MessageModal open={isMessageModalOpen} onClose={closeMessageModal} />
		</Styled.Wrapper>
	);
};

export default DiscoveryContainer;
