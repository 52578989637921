import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { devices } from 'styles/variables/media-queries';

export const Container = styled.div`
	max-width: 250px;
	max-height: 290px;
	height: 100%;
	margin: 0 auto;
	@media screen and (${devices.lg}) {
		margin: 0;
	}
`;

export const ViewportContainer = styled.div`
	overflow: hidden;
	margin-bottom: 16px;
	border-radius: ${({ theme }) => theme.radius.default};
`;

export const InnerContainer = styled.div`
	display: flex;
	touch-action: pan-y pinch-zoom;
	margin-left: calc(var(16px) * -1);
`;

export const Slide = styled.div`
	transform: translate3d(0, 0, 0);
	flex: 0 0 100%;
	min-width: 0;
`;

export const ImageDiv = styled.div<{ src: string }>`
	height: 250px;
	width: 250px;
	background-color: ${colors.mist};
	background-image: url(${(props) => props.src});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0;
`;

export const CarouselButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #282626a6;
	border: none;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	transition: background-color 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		background-color: ${colors.black};
	}

	.icon {
		line-height: 0;
		width: 7px; // Set with to make the icon centered
		path {
			fill: ${colors.white};
		}
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 0.5rem;

	> button:first-child .icon {
		width: auto;
	}
`;

export const NavigationContainer = styled.div`
	gap: 0.25rem;
	margin-bottom: 8px;
	position: relative;
	background-color: #282626a6;
	border-radius: 10px;
	padding: 0.5rem;
	overflow: hidden;
	text-align: center;
	display: flex;
`;

export const NavigationButton = styled.button`
	width: 8px;
	height: 8px;
	display: inline-block;
	background: ${colors.silver};
	padding: 0;
	border: 0;
	border-radius: 50%;
	transition:
		background-color 0.2s,
		width 0.2s;
	cursor: pointer;

	&.embla__dot--selected {
		background: ${colors.white};
		width: 16px;
		border-radius: 10px;
	}
`;
